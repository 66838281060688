<app-main-header></app-main-header>
<main>
  <div class="container">
    <div class="search">
      <!-- <app-search-header
        [alreadySelectedCityId]="selectedCityId"
        [alreadySelectedTypeId]="selectedTypeId"
        (selectedCityId$)="onSelectCity($event)"
        (selectedCityName$)="getSelectedCityName($event)"
        (selectedTypeId$)="onSelectType($event)"
        (selectedTypeName$)="getSelectedType($event)"
      ></app-search-header> -->
      <app-search
        [alreadySelectedTypeId]="selectedTypeId"
        [alreadySelectedCityId]="selectedCityId"
        [alreadySelectedSearchMode]="searchMode"
        [alreadySelectedLatAndLng]="selectedLatAndLng"
        (selectedCityName$)="getSelectedCityName($event)"
        *ngIf="instituteTypes.length"
        [minimalUI]="true"
        [instituteTypes]="instituteTypes"
      >
      </app-search>
    </div>
    <div class="list">
      <h2 *ngIf="institutes.length > 0">
        {{ institutes.length }} institutes found for
        {{ selectedTypeName | titlecase }}
        {{
          selectedCityName === "LOCATE_ME"
            ? " near you"
            : " in " + (selectedCityName | titlecase)
        }}
      </h2>
      <div class="list__item" *ngFor="let insitute of institutes">
        <app-institute-card
          [latitude]="selectedLatAndLng?.latitude"
          [longitude]="selectedLatAndLng?.longitude"
          [searchMode]="searchMode"
          [institute]="insitute"
        ></app-institute-card>
      </div>
      <p *ngIf="institutes.length === 0 && !isLoading">No Institute Found</p>
    </div>
  </div>
</main>
<app-main-footer></app-main-footer>
