import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CitiesByInstitute,
  CommonService,
  InstituteTypes,
} from '../services/common.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastService } from '../shared/services/toast.service';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { normalizeTypeName } from '../utils/string'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  searchInput = new Subject<string>();

  @Input() instituteTypes: InstituteTypes[] = [];
  @ViewChild('locationSelect') locationSelect!: NgSelectComponent;
  @Input() minimalUI = false;
  @Input() alreadySelectedTypeId!: string;
  @Input() alreadySelectedCityId!: string;
  @Input() alreadySelectedSearchMode!: 'BY_CITY' | 'LOCATE_ME' | 'BY_INSTITUTE';
  @Input() alreadySelectedLatAndLng!: { latitude: number; longitude: number };
  @Output() selectedCityName$: EventEmitter<string> = new EventEmitter();

  @ViewChild('categoryContainer') categoryContainer!: ElementRef;

  private _commonService = inject(CommonService);
  _spinner = inject(SpinnerVisibilityService);
  _toastService = inject(ToastService);
  _router = inject(Router);

  cities: CitiesByInstitute[] = [];

  searchType: string = '';
  searchCity: string = '';
  showSuggestions: boolean = false;
  showLocationSuggestions: boolean = false;
  filteredCities: CitiesByInstitute[] = [];
  filteredTypeAndInstitutes: any[] = []

  locateMe!: { latitude: number; longitude: number };
  searchMode: 'BY_CITY' | 'LOCATE_ME' | 'BY_INSTITUTE' = 'BY_CITY';
  selectedCityId: string='65fa9e7963c2ee5fb9053727';
  selectedTypeOrInstituteId!: string;
  selectedTypeName!: string;
  selectedCityName!: string;
  selectedId!: string;
  selectedInstituteId!: string; // WIP
  locatedCityId!: string | null; //this var use to store cityId when near me selecte
  
  ngOnDestroy() {
    this.searchInput.complete();
  }

  ngOnInit(): void {
    // Debounce search
    // Code taken from here: https://v17.angular.io/guide/http-optimize-server-interaction
    this.searchInput
      .pipe(debounceTime(500)) // debounce so that server is not loaded with API calls
      .subscribe((searchTerm: string) => {
        // Call your search function here
        this.fetchTypeAndInstitutes({
          institute: true,
          search: searchTerm
        })
      });

    // this.filteredInstituteTypes = this.instituteTypes;
    if (this.alreadySelectedTypeId)
      this.selectedTypeOrInstituteId = this.alreadySelectedTypeId;
    if (this.alreadySelectedCityId)
      this.selectedCityId = this.alreadySelectedCityId;
    if (this.alreadySelectedSearchMode) {
      this.searchMode = this.alreadySelectedSearchMode;
      this.locatedCityId =
        this.alreadySelectedSearchMode === 'LOCATE_ME'
          ? this.selectedCityId
          : null;
      if (this.searchMode === 'LOCATE_ME')
        this.selectedCityId = this.searchMode;
    }
    if (
      this.alreadySelectedLatAndLng &&
      this.alreadySelectedLatAndLng.latitude &&
      this.alreadySelectedLatAndLng.longitude
    ) {
      this.locateMe = this.alreadySelectedLatAndLng;
    }
    this.fetchTypeAndInstitutes({institute: true})
    this.fetchCities();
    this.setInstitutesTypeName();
    this.emitSelectedCityName();
  }

  scrollCategories(direction: string) {
    const container = this.categoryContainer.nativeElement;
    const scrollAmount = 100; // Adjust the scroll amount as needed

    if (direction === 'left') {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else if (direction === 'right') {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }

  fetchCities() {
    this._commonService.fetchCityByInsitute().subscribe({
      next: (_: any) => {
        this.filteredCities = JSON.parse(JSON.stringify(_.data));
        this.cities = _.data;
        this.setCityName();
        this.emitSelectedCityName();
      },
      error: (__: any) => {
        this._toastService.showError(__);
      },
    });
  }

  fetchTypeAndInstitutes(params: any){
    this.filteredTypeAndInstitutes = []
    this._commonService.fetchInstituteTypes(params).subscribe({
      next:(response:any) => {

        // Create data that can be grouped by ng-select
        // Read this on how to correctly push data for ng-select items: https://www.npmjs.com/package/@ng-select/ng-select#change-detection

        // First push all the institute Types
        response.data.forEach((instituteType: any) => {
          this.filteredTypeAndInstitutes = [...this.filteredTypeAndInstitutes, {
            ...instituteType,
            name: instituteType.type,
            groupName: 'Types'
          }];
        })

        // Next push all the institutes
        response.institutes.forEach((institute: any) => {
          this.filteredTypeAndInstitutes = [...this.filteredTypeAndInstitutes, {
            ...institute,
            groupName: 'Institutes'
          }];
        })        
      },  
      error: (error: any) => {
        console.error('Error fetching institute type and Institutes', error);
      }
    })
  }

  onLocateUser() {
    console.log('ON LOCATE');

    if (navigator.geolocation) {
      this._spinner.show();
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.locateMe = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          this.getCityName(this.locateMe.latitude, this.locateMe.longitude);
          this.searchCity = 'Near Me';
          this.searchMode = 'LOCATE_ME';
        },
        (error) => {
          this._spinner.hide();
          console.error('Error getting location: ', error);
          alert(
            'Error getting location. Please try again. Error is : ' +
              error.message
          );
        },
        {
          enableHighAccuracy: true,
          timeout: 8000,
          maximumAge: 0,
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  getCityName(lat: number, lng: number) {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBiNvL3i-9eDytiDIYaT1mbWShgMppF5i0`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;
          const cityComponent = addressComponents.find(
            (component: any) =>
              component.types.includes('locality') ||
              component.types.includes('administrative_area_level_1')
          );

          const matchedCity = this.cities.find(
            (city: any) =>
              city.name.toLowerCase() === cityComponent.long_name.toLowerCase()
          );

          if (matchedCity) {
            this.locatedCityId = matchedCity._id;
          } else {
            this._toastService.showError(
              'Shikshatam is not reached to your city yet.'
            );
          }
        } else {
          this._toastService.showError('Unable to fetch location data.');
        }
        this._spinner.hide();
      })
      .catch((error) => {
        this._spinner.hide();
        this._toastService.showError('Unable to fetch location data.');
        console.error('Error fetching city name: ', error);
      });
  }

  // USE BELOW LOGIC IF GOOGLE MAPS APIS COSTING IS HIGH
  // getCityName(lat: number, lng: number) {
  //   const apiUrl = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;
  //   fetch(apiUrl)
  //     .then(response => response.json())
  //     .then(data => {
  //       this.searchCity = data.address.city || data.address.town || data.address.village || 'Unknown location';
  //     })
  //     .catch(error => {
  //       console.error('Error fetching city name: ', error);
  //     });
  // }

  // filterTypes() {
  //   if (this.searchType.trim() === '') {
  //     this.filteredInstituteTypes = this.instituteTypes; // Show all if search is empty
  //   } else {
  //     this.filteredInstituteTypes = this.instituteTypes.filter((institute) =>
  //       institute.type.toLowerCase().includes(this.searchType.toLowerCase())
  //     );
  //   }
  //   this.showSuggestions = this.filteredInstituteTypes.length > 0;
  // }

  setInstitutesTypeName() {
    let filteredInstituteTypes: InstituteTypes[] = [];
    filteredInstituteTypes = this.instituteTypes;
    const selectedName = filteredInstituteTypes.find(
      (type) => type._id === this.selectedTypeOrInstituteId
    );

    if (selectedName) {
      this.selectedTypeName = normalizeTypeName(selectedName.type)
    } else {
      this.selectedTypeName = ''; // Provide a default value if undefined
    }
  }

  setCityName() {
    const selectedCity = this.cities.find(
      (city) => city._id === this.selectedCityId
    );
    if (selectedCity) {
      this.selectedCityName = selectedCity.name.toLowerCase();
    }
  }

  filterLocations() {
    if (this.searchCity === '') {
      this.filteredCities = this.cities;
      this.filteredCities = this.cities.filter((city) =>
        city.name.toLowerCase().includes(this.searchCity.toLowerCase())
      );
    }
    this.showLocationSuggestions = this.filteredCities.length > 0;
  }

  onSelectInstituteType(id: string) {
    this.selectedTypeOrInstituteId = id;

    this.setInstitutesTypeName();
    setTimeout(() => {
      if (this.locationSelect) {
        this.locationSelect.open(); // focus the ng-select dropdown
      } 
    }, 200);
    
  }

  onSelectTypeOrInstitute(id: string){
    this.selectedTypeOrInstituteId = id;

    // Figure out if the selected item is a type or an institute
    const foundItem = this.filteredTypeAndInstitutes.find(item => item._id === id)

    if(foundItem.groupName === 'Types'){
      // If the selected item is a type, reset the search mode to city
      this.searchMode = this.selectedCityId === 'LOCATE_ME' ? 'LOCATE_ME' : 'BY_CITY'
      this.onSelectInstituteType(id)
    } else {
      this.searchMode = 'BY_INSTITUTE'
    }

  }

  onSelectCity(selectedId: string) {
    console.log('ON SE CI : ', selectedId);

    if (!selectedId) return;

    if (selectedId == 'LOCATE_ME') {
      this.onLocateUser();
      return;
    }

    this.searchMode = 'BY_CITY';
    this.selectedCityId = selectedId;
    this.locatedCityId = null;
    this.setCityName();
    // this.searchCity = this.cities.find((_) => _._id === city._id)?.name || '';
    // this.filteredCities = [];
    // setTimeout(() => {
    //   this.showLocationSuggestions = false;
    // }, 100);
  }

  emitSelectedCityName() {
    if (this.searchMode === 'LOCATE_ME'){
      this.selectedCityName$.emit('LOCATE_ME');
  }   else {
      this.selectedCityName$.emit(this.selectedCityName);
    }
  }

  onSearch() {
    let cityNameInUrl = this.selectedCityName
    if (this.searchMode == 'LOCATE_ME') {
      cityNameInUrl= 'near-me';

    }
    if (!this.selectedTypeOrInstituteId) {
      this._toastService.showError(
        'Please select a type or an institute.'
      );
      return;
    }
    if (this.searchMode === 'BY_CITY') {
      if (!this.selectedCityId) {
        this._toastService.showError(
          'Please provide your location, city, teacher or institute you are looking for.'
        );
        return;
      }
      this._router.navigateByUrl(
        `/listing/${cityNameInUrl}/${this.selectedTypeName}?city=${this.selectedCityId}&type=${this.selectedTypeOrInstituteId}&searchMode=${this.searchMode}`
      );
    }

    if (this.searchMode === 'BY_INSTITUTE') { 
      const foundItem = this.filteredTypeAndInstitutes.find(item => item._id === this.selectedTypeOrInstituteId)
    
        this._router.navigateByUrl('institute/' + foundItem.urlCode);
        return;
    }

    if (this.searchMode === 'LOCATE_ME') {
      if (!this.locateMe.latitude || !this.locateMe.longitude) {
        this._toastService.showError(
          'To search teacher or institute near you location needed.'
        );
        return;
      }
      this._router.navigateByUrl(
        `/listing/${cityNameInUrl}/${this.selectedTypeName}?type=${this.selectedTypeOrInstituteId}&city=${this.locatedCityId}&latitude=${this.locateMe.latitude}&longitude=${this.locateMe.longitude}&searchMode=${this.searchMode}`
      );
    }

    // Inform parent about change in city name when search begins
    this.emitSelectedCityName();
  }

  hideSuggestions() {
    setTimeout(() => {
      this.showSuggestions = false;
    }, 500); // Small delay to ensure the click event is processed
  }

  hideLocationSuggestions() {
    setTimeout(() => {
      this.showLocationSuggestions = false;
    }, 500); // Small delay to ensure the click event is processed
  }

  onTypeAndInstituteSearch({term: searchTerm}: {term: string}){
    this.searchInput.next(searchTerm);
  }

  onTypeAndInstituteClear(){
    // Refetch the entire list when search term is cleared
    this.fetchTypeAndInstitutes({
      institute: true
    })
  }
}
