
import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  CommonService,
  InstituteTypes,
  CitiesByInstitute,
} from '../services/common.service';
import { Title,Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { normalizeTypeName } from '../utils/string'
import { MetaService } from '../utils/meta';


declare var google: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  instituteTypes: InstituteTypes[] = [];
  allInstituteTypes: InstituteTypes[] = [];
  allCities: CitiesByInstitute[] = [];
  selectedCityId: string = '65fa9e7963c2ee5fb9053727';
  popularCitiesItems: any[] = []
  showPopularCities: boolean = true
  activeCityTab!:any

  constructor(
    private commonService: CommonService,
    private router: Router,
    private metaService:MetaService,
    private meta:Meta
  ) {}

 
  ngOnInit(): void { 
    this.fetchInstituteTypes();
    this.fetchCities();
    this.setMetaInfo()
  }

  ngAfterViewInit(): void {
    this.intializeMap(); 
  }

  fetchInstituteTypes() {
    this.commonService.fetchInstituteTypes().subscribe({
      next: (response: any) => {
        this.allInstituteTypes = response.data;

        this.instituteTypes = response.data.filter((_: any) => _.isPopular);
      },
      error: (error: any) => {
        console.error('Error fetching institute types:', error);
        alert('Failed to fetch institute types. Please try again later.');
      },
    });
  }

  fetchCities() {
    //City
    this.commonService.fetchCityByInsitute().subscribe({
      next: (response: any) => {
        this.allCities = response.data;
        this.generatePopularCitiesItems()
      },
      error: (error: any) => {
        console.error('Error fetching institute types:', error);
        alert('Failed to fetch institute types. Please try again later.');
      },
    });
  }

  onSelectInstituteType(typeId: string) {
    let selectedTypeName = '';
    let selectedCityName = '';
    //Type Name
    const selectedInstituteType = this.instituteTypes.find(
      (type) => type._id === typeId
    );
    if (selectedInstituteType) {
      selectedTypeName = normalizeTypeName(selectedInstituteType.type) 
    }
    //City
    const selectedCity = this.allCities.find(
      (type) => type._id === this.selectedCityId
    );
    if (selectedCity) {
      selectedCityName = selectedCity?.name.toLowerCase()
    }
    this.router.navigateByUrl(
      `/listing/${selectedCityName}/${selectedTypeName}?city=${this.selectedCityId}&type=${typeId}&searchMode=BY_CITY`
    );
  }

  setMetaInfo(){
    const title= 'Best Home Tutors Near Me | Expert Virtual Learning Help'
    const description='Looking for the best home tutors near me? Explore websites for affordable home tuition and access virtual learning platforms to prepare for board exams efficiently.'
    const keyword= 'maths tuition near me, spoken english classes near me, mathematics tuition, engineering tuition near me, bcom classes near me, free dance courses,  home tuition for class 1 to 5 near me, iit , jee , best institute in indore , UPSC'
    this.metaService.setMeta(title,description,keyword)

    this.meta.updateTag({
          property: 'og:title',
          content: 'Find Best Institute Near Me',
        });
        this.meta.updateTag({
          property: 'og:description',
          content:
            'Shikshatam is an Education Management Platform (EMP) that will work together with Teachers and Students to simplify lives. Shikshatam helps to find the best institute near you. NEET, UPSC, JEE .',
        });
        this.meta.updateTag({
          property: 'og:image',
          content: 'src/assets/images/logo.png',
        });
        this.meta.updateTag({
          property: 'og:url',
          content: 'https://www.shikshatam.com',
        });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
  }

  intializeMap() {
    var myLatLng = new google.maps.LatLng(
      22.753520493778122,
      75.91159875379982
    );
    var mapOptions = {
      zoom: 14,
      center: myLatLng,
      disableDefaultUI: true,
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: false,
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'roadatlas'],
      },
    };
    var map = new google.maps.Map(
      document.getElementById('map_canvas'),
      mapOptions
    );
    var marker = new google.maps.Marker({
      position: myLatLng,
      map: map,
      // icon: "assets/images/location-icon.png",
      title: '',
    });
  }

  generatePopularCitiesItems(){
    this.activeCityTab = this.allCities[0]._id
    this.popularCitiesItems = this.allCities.map(cityObj =>  {
      const cityLinks = this.allInstituteTypes.map(category => ({
        ...category,
        text: `${category.type} in ${cityObj.name}`,
        route: `/listing/${normalizeTypeName(cityObj.name)}/${normalizeTypeName(category.type)}`,
        queryParams:{city:cityObj._id,type:category._id, searchMode:'BY_CITY'}
      }));

      return {
        ...cityObj,
        links: cityLinks
      }
    })
  }

  onClickCityTab(cityId:string){
    this.activeCityTab = cityId
  }
}
