import { Component, inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss',
})
export class ContactFormComponent implements OnInit {
  @Input() instituteId!: string;
  leadForm!: FormGroup;
  _formBuilder = inject(FormBuilder);
  _common = inject(CommonService);
  private _modalService = inject(NgbModal);

  ngOnInit(): void {
    this.buildForm();
  }
  buildForm() {
    this.leadForm = this._formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[6-9][0-9]{9}$'),
      ]),
      message: new FormControl(null, [Validators.required]),
    });
  }

  onSubmitRequest() {
    if (!this.leadForm.valid) {
      alert('All fields are required');
      return;
    }
    this.leadForm.value.institute = this.instituteId;
    this._common.submitInstituteLead(this.leadForm.value).subscribe({
      next: (_) => {
        this.leadForm.reset();
        alert('Institute will contact you soon!');
        this.close();
      },
      error: (_) => {
        alert(_);
      },
    });
  }

  close() {
    this._modalService.dismissAll();
  }
}
