import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../utils/meta';
@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss',
})
export class PrivacyComponent implements OnInit {
  constructor(private metaService: MetaService) {}
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.setMetaInfo();
  }

  setMetaInfo() {
    const title = 'Shikshatam - Privacy Policy';
    const description =
      'Looking for the best home tutors near me? Explore websites for affordable home tuition and access virtual learning platforms to prepare for board exams efficiently.';
    const keyword =
      'maths tuition near me, spoken english classes near me, mathematics tuition, engineering tuition near me, bcom classes near me, free dance courses,  home tuition for class 1 to 5 near me, iit , jee , best institute in indore , UPSC';
    this.metaService.setMeta(title, description, keyword);
  }
}
