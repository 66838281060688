<app-main-header></app-main-header>
<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-lg-8 col-md-10 col-sm-12">
      <article *ngIf="article; else noArticle">
        <section class="mb-4">
          <h1 class="h4 font-weight-bold text-primary">{{ article.title }}</h1>
          <div
            [innerHTML]="article.content"
            class="mt-3"
            style="
              font-family: 'Times New Roman', Times, serif;
              line-height: 1.6;
              color: #333;
            "
          ></div>
        </section>
      </article>
      <ng-template #noArticle>
        <section class="mb-4 text-center">
          <h2 class="h4 font-weight-bold text-danger">Article Not Found</h2>
          <p class="lead">We couldn't find the article you were looking for.</p>
        </section>
      </ng-template>
    </div>
  </div>
</div>
<app-main-footer></app-main-footer>
