import { response } from 'express';
import { Component, inject, OnInit } from '@angular/core';
import { InstituteListings, ListingService } from '../services/listing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { ToastService } from '../shared/services/toast.service';
import { CommonService, InstituteTypes } from '../services/common.service';
import { MetaService } from './../utils/meta';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
  providers: [ListingService],
  host: { ngSkipHydration: 'true' },
})
export class ListingComponent implements OnInit {
  _service = inject(ListingService);
  _commonService = inject(CommonService);
  _route = inject(ActivatedRoute);
  _router = inject(Router);
  institutes: InstituteListings[] = [];
  selectedCityId!: string;
  selectedTypeId!: string;
  searchMode!: 'BY_CITY' | 'LOCATE_ME' | 'BY_INSTITUTE';
  selectedLatAndLng!: { latitude: number; longitude: number };

  instituteTypes: InstituteTypes[] = [];

  selectedCityName!: string;
  selectedTypeName!: string;
  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _toastService: ToastService,
    private metaService: MetaService,
    private meta: Meta,
    private http: HttpClient
  ) {}

  resetSearch = () => {
    this._toastService.showError('Something went wrong please search again');
    this._router.navigateByUrl('/');
  };

  ngOnInit() {
    this._route.queryParams.subscribe({
      next: (params: any) => {
        if (!params.hasOwnProperty('searchMode')) {
          this.resetSearch();
        }
        this.searchMode = params['searchMode'];
        switch (this.searchMode) {
          case 'BY_CITY':
            if (
              params.hasOwnProperty('city') &&
              params.hasOwnProperty('type')
            ) {
              this.selectedCityId = params['city'];
              this.selectedTypeId = params['type'];
              this.fetchInstitutes();
            } else {
              this.resetSearch();
            }
            break;
          case 'BY_INSTITUTE':
            break;
          case 'LOCATE_ME':
            if (
              params.hasOwnProperty('latitude') &&
              params.hasOwnProperty('longitude') &&
              params.hasOwnProperty('city') &&
              params.hasOwnProperty('type')
            ) {
              this.selectedLatAndLng = {
                latitude: params['latitude'],
                longitude: params['longitude'],
              };
              this.selectedCityId = params['city'];
              this.selectedTypeId = params['type'];

              this.fetchInstitutes();
            } else {
              this.resetSearch();
            }
            break;
          default:
            // this.resetSearch();
            break;
        }
      },
    });
  }

  fetchInstituteTypes() {
    this._commonService.fetchInstituteTypes().subscribe({
      next: (response: any) => {
        this.instituteTypes = response.data;
        const selectedType = this.instituteTypes.find(
          (type) => type._id === this.selectedTypeId
        );
        if (selectedType) {
          this.selectedTypeName = selectedType.type;
        }
      },
      error: (error: any) => {
        this._toastService.showError(
          'Failed to fetch institute types. Please try again later.'
        );
      },
    });
  }

  fetchInstitutes() {
    this.fetchInstituteTypes();
    this.isLoading = true;
    if (!this.selectedCityId || !this.selectedTypeId) {
      this.resetSearch();
      return;
    }
    if (this.searchMode === 'LOCATE_ME')
      if (
        !this.selectedLatAndLng.latitude ||
        !this.selectedLatAndLng.longitude
      ) {
        this.resetSearch();
        return;
      }

    let params =
      this.searchMode === 'BY_CITY'
        ? { cityId: this.selectedCityId, typeId: this.selectedTypeId }
        : this.searchMode === 'LOCATE_ME'
        ? {
            lat: this.selectedLatAndLng.latitude,
            lng: this.selectedLatAndLng.longitude,
            cityId: this.selectedCityId,
            typeId: this.selectedTypeId,
            searchMode: this.searchMode,
          }
        : null;
    if (params)
      this._service.fetchListing(params).subscribe({
        next: (_) => {
          this.institutes = _.data;
          this.setMetaInfo();

        },
        error: (_) => {
          alert(_);
        },
        complete: () => {
          this.isLoading = false;
      
        },

      });
  }

  onSelectCity(e: string) {
    if (!e) return;
    if (this.selectedCityId === e) return;
    this.selectedCityId = e;
    this.fetchInstitutes();
  }

  onSelectType(e: string) {
    if (!e) return;
    if (this.selectedTypeId === e) return;
    this.selectedTypeId = e;
    this.fetchInstitutes();
  }

  getSelectedCityName(e: string) {
    // if (!e) return;
    // if (this.selectedCityName === e) return;
    this.selectedCityName = e;
    // this.fetchInstitutes();
    this.setMetaInfo()  //handel the case when searching on home page 
   
  }

  getSelectedType(e: string) {
    if (!e) return;
    if (this.selectedTypeName === e) return;
    this.selectedTypeName = e;
    this.fetchInstitutes();
  }

  updateQueryParams() {
    return;
    if (this.selectedCityName && this.selectedTypeName) {
      console.log(this.selectedCityName);
      console.log(this.selectedTypeName);
      let city = this.selectedCityName || 'indore'; // Replace 'defaultCity' with your default city name
      let type = this.selectedTypeName || 'iit jee'; // Replace 'defaultType' with your default type name

      const lowerCaseCityName = city.toLowerCase();
      const lowerCaseTypeName = type.toLowerCase();

      this._router.navigate(['/listing', lowerCaseCityName, lowerCaseTypeName]);
      this.fetchInstitutes(); // Ensure this function fetches data based on the updated query parameters
    }
  }

  setMetaInfo() {
    // Default meta tags in case the response fails or is incomplete
    const defaultTitle = `Best ${this.selectedTypeName} in ${this.selectedCityName}`;
    const defaultDescription = `Find the best ${this.selectedTypeName} in ${this.selectedCityName}`;
    const defaultKeyword = `${this.selectedTypeName} , ${this.selectedCityName}`;
    this.http.get('/assets/metaTags.json').subscribe({
      next: (response: any) => {
        const {
          title = defaultTitle,
          description = defaultDescription,
          keyword = defaultKeyword,
        } = response[`${this.selectedTypeName}`] || {};
        this.metaService.setMeta(title, description, keyword);
      },
      error: (error: any) => {
        console.error('Error fetching meta tags:', error);
        // Use default meta tags in case of an error
        // this.metaService.setMeta('listing', defaultTitle, defaultDescription, defaultKeyword);
      },
    });

    this.meta.updateTag({ property: 'og:title', content: defaultTitle });
    this.meta.updateTag({
      property: 'og:description',
      content: defaultDescription,
    });
  }
}
