import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { MainHeaderComponent } from './main-header/main-header.component';
import { SearchHeaderComponent } from './search-header/search-header.component';
import { InstituteCardComponent } from './institute-card/institute-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { TeacherCardComponent } from './teacher-card/teacher-card.component';
import { BatchCardComponent } from './batch-card/batch-card.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FieldErrorsComponent } from './components/field-errors/field-errors.component';
import { ToastComponent } from './components/toast/toast.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { provideClientHydration } from '@angular/platform-browser';
import { SearchComponent } from '../search/search.component';
import { NgHttpLoaderModule } from 'ng-http-loader';

@NgModule({
  declarations: [
    MainHeaderComponent,
    MainFooterComponent,
    SearchHeaderComponent,
    InstituteCardComponent,
    TeacherCardComponent,
    BatchCardComponent,
    ContactFormComponent,
    FieldErrorsComponent,
    ToastComponent,
    SearchComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    NgbModalModule,
    NgOptimizedImage,
    NgHttpLoaderModule.forRoot(),
    FormsModule,
  ],
  exports: [
    MainHeaderComponent,
    MainFooterComponent,
    SearchHeaderComponent,
    InstituteCardComponent,
    TeacherCardComponent,
    BatchCardComponent,
    ContactFormComponent,
    FieldErrorsComponent,
    ToastComponent,
    SearchComponent,
  ],
  providers: [provideClientHydration()],
})
export class SharedModule {}
