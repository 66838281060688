import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private meta: Meta, private titleService: Title) {}

  setMeta(title: string, description: string, keyword: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({
      name: 'description',
      content: description,
    });
    this.meta.updateTag({
      name: 'keywords',
      content: keyword,
    });
  }
}
