import { MetaService } from './../../utils/meta';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss',
})
export class TermsComponent implements OnInit {
  constructor(
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.setMetaInfo()
  }
  setMetaInfo() {
    const title = 'Shikshatam - Terms and Conditions';
    const description =
      'Looking for the best home tutors near me? Explore websites for affordable home tuition and access virtual learning platforms to prepare for board exams efficiently.';
    const keyword =
      'maths tuition near me, spoken english classes near me, mathematics tuition, engineering tuition near me, bcom classes near me, free dance courses,  home tuition for class 1 to 5 near me, iit , jee , best institute in indore , UPSC';
    this.metaService.setMeta(title, description, keyword);
  }
}
