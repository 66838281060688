<app-main-header id="top"></app-main-header>

<div class="mobile-menu-action">
  <button
    class="btn btn-outline-primary mx-2"
    routerLink="/"
    fragment="join_us_mobile"
  >
    Enquiry
  </button>
  <button class="btn btn-primary register-btn" [routerLink]="'/register'">
    Register
  </button>
</div>
<div class="search">
  <!-- <app-search-header
    (selectedCityId$)="onSelectCity($event)"
    (selectedTypeId$)="onSelectInstituteType($event)"
  ></app-search-header> -->
</div>
<main>
  <!-- <div class="container text-center mt-5">
    <h1 class="display-4 mb-4">Find Best Institute Near You!</h1>
    <div class="search-bar mt-4 mb-4 d-flex justify-content-center align-items-center">
      <div class="input-group">
        <input type="text" class="form-control" placeholder='Try "Select Type"' aria-label="Search for tuition">
        <input type="text" class="form-control" placeholder="Select location" aria-label="Course location">
        <button class="btn btn-danger">Search</button>
      </div>
    </div>
    <div class="categories mt-4 d-flex justify-content-center align-items-center">
      <button class="btn btn-outline-secondary btn-circle" id="scrollLeft">
        &larr;
      </button>
      <div class="category-container d-inline-block mx-3">
        <ng-container *ngFor="let type of instituteTypes; let i = index">
          <span class="category-item" (click)="onSelectInstituteType(type._id)">
            {{ type.type }}
          </span>
        </ng-container>
      </div>
      <button class="btn btn-outline-secondary btn-circle" id="scrollRight">
        &rarr;
      </button>
    </div>
  </div>
   -->
  <app-search
    *ngIf="allInstituteTypes.length"
    [instituteTypes]="allInstituteTypes"
  ></app-search>

  <!-- <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-auto">
        <div class="card shadow-lg border-0 rounded" style="width: 14rem">
          <img
            src="assets\images\kids.jpg"
            class="card-img-top rounded-top"
            alt="Doctor Image"
          />
          <div class="card-body text-center">
            <h5 class="card-title fw-bold">Find Kids Tuition Near You...</h5>
            <p class="card-text text-secondary">
              Connect with teachers instantly
            </p>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="card shadow-lg border-0 rounded" style="width: 14rem">
          <img
            src="assets\images\medical.jpg"
            class="card-img-top rounded-top"
            alt="Doctor Image"
          />
          <div class="card-body text-center">
            <h5 class="card-title fw-bold">
              Find Medical Coaching Near You...
            </h5>
            <p class="card-text text-secondary">Verified Institutes.</p>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="card shadow-lg border-0 rounded" style="width: 14rem">
          <img
            src="assets\images\library.jpg"
            class="card-img-top rounded-top"
            alt="Doctor Image"
          />
          <div class="card-body text-center">
            <h5 class="card-title fw-bold">Find Library Near You ...</h5>
            <p class="card-text text-secondary">
              Best place for focused studies.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <section class="hierarchy">
    <div class="container">
      <div class="parent">
        SHIKSHATAM
        <!-- <img src="../../assets/images/logo.png" alt="Shikshatam" /> -->
      </div>
      <div class="children">
        <div
          (click)="onSelectInstituteType(type._id)"
          class="child"
          *ngFor="let type of instituteTypes; let i = index"
        >
          <!-- <div
          (click)="onSelectInsituteType(type._id)"
          class="child"
          [style.margin-top]="i > 4 ? '20px' : ''"
          *ngFor="let type of instituteTypes; let i = index"
        > -->
          <div class="child__wrapper">
            <div class="child__bg">
              <img src="../../assets/images/shop.png" [alt]="type.type" />
            </div>
            <div class="child__name">{{ type.type }}</div>
          </div>
        </div>
        <h1 class="hierarchy__title">Your Guide to the Best Coaching Classes</h1>
      </div>
    </div>
  </section>
  <section id="join_us" class="contact">
    <div class="container">
      <div class="row mb50">
        <!-- <div
          class="sec-title text-center mb50 wow fadeInDown animated"
          data-wow-duration="500ms"
        >
          <h2>Join Shikshatam Today!</h2>
        </div> -->
        <!-- contact form -->
        <div
          class="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInLeft animated"
          data-wow-duration="500ms"
        >
          <div class="contact-address">
            <div
              class="sec-title text-center mb50 wow fadeInDown animated"
              data-wow-duration="500ms"
            >
              <h2>Transforming Classrooms with Virtual Platforms</h2>
            </div>

            <p style="font-size: 21px; margin-bottom: 16px">
              We believe that teachers are the true architects of the future.
              Shikshatam is committed to providing educators with the tools,
              resources, and platforms they need to excel in their careers.
            </p>

            <p style="font-size: 21px; margin-bottom: 16px">
              Our goal is to empower teachers to innovate in their teaching
              methods, inspire their students with the right information, and
              make a lasting impact on their academic journey. Whether you're
              looking for mathematics tuition near you or spoken English
              classes, we have you covered.
            </p>

            <p style="font-size: 21px; margin-bottom: 16px">
              By providing robust support to educators, we ensure that students
              have access to the best resources and mentorship, enabling them to
              reach their highest potential. From physics tutors to home tuition
              for class 1 to 5 near you, we facilitate various educational
              needs.
            </p>

            <p style="font-size: 21px; margin-bottom: 16px">
              By encouraging these relationships, we open doors for students by
              introducing them to outstanding teachers who can mentor and assist
              them in realizing their goals. Our network includes tuition
              centres near you and tuition teachers who can provide personalized
              attention.
            </p>

            <p style="font-size: 21px">
              Shikshatam envisions a future where every teacher has the
              opportunity to grow professionally and every student has access to
              the highest quality education. By bridging the gap between
              teachers and students, we aim to create a robust educational
              ecosystem that fosters continuous learning and growth. Explore our
              platform for engineering tuition near you and BCom classes.
            </p>
          </div>
        </div>
        <div
          id="join_us_mobile"
          class="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown animated"
          data-wow-duration="500ms"
          data-wow-delay="300ms"
        >
          <div
            class="sec-title text-center mb50 wow fadeInDown animated"
            data-wow-duration="500ms"
          >
            <h2>Start with Shikshatam Now!</h2>
          </div>

          <div class="join-us__form mb50">
            <form
              ngNoForm
              id="contact-form"
              method="post"
              action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DHs000003XHzH%22"
            >
              <input type="hidden" name="oid" value="00DHs000003XHzH" />
              <input
                type="hidden"
                name="retURL"
                value="https://www.shikshatam.com?submitted=true"
              />
              <div class="contact-form">
                <div class="input-group">
                  <div class="input-field">
                    <input
                      id="first_name"
                      maxlength="40"
                      name="first_name"
                      size="20"
                      placeholder="First Name"
                      type="text"
                      required
                      oninvalid="this.setCustomValidity(`come on, you have a name don't you?`)"
                      oninput="this.setCustomValidity('')"
                      class="form-control"
                    />
                  </div>

                  <div class="input-field">
                    <input
                      id="last_name"
                      maxlength="80"
                      name="last_name"
                      size="20"
                      placeholder="Last Name"
                      type="text"
                      required
                      oninvalid="this.setCustomValidity(`last name is required!`)"
                      oninput="this.setCustomValidity('')"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="input-group">
                  <div class="input-field">
                    <input
                      id="mobile"
                      maxlength="40"
                      name="mobile"
                      placeholder="Mobile Number"
                      size="20"
                      type="tel"
                      class="form-control"
                      required
                      oninvalid="this.setCustomValidity(`um..., the number you are trying to call is invalid!`)"
                      oninput="this.setCustomValidity('')"
                    />
                  </div>

                  <div class="input-field">
                    <input
                      id="email"
                      maxlength="80"
                      name="email"
                      placeholder="Email"
                      size="20"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-field" style="width: 100%">
                    <textarea
                      id="street"
                      placeholder="Street Address"
                      class="form-control"
                      name="street"
                      required
                      oninvalid="this.setCustomValidity(`Hey!, we are lost help us by telling your address.`)"
                      oninput="this.setCustomValidity('')"
                    ></textarea
                    ><br />
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-field">
                    <input
                      id="city"
                      maxlength="40"
                      name="city"
                      placeholder="City"
                      size="20"
                      type="text"
                      class="form-control"
                      required
                      oninvalid="this.setCustomValidity(`opps!, this city not found on earth!`)"
                      oninput="this.setCustomValidity('')"
                    />
                  </div>

                  <div class="input-field">
                    <input
                      id="state"
                      maxlength="80"
                      name="state"
                      placeholder="State"
                      type="text"
                      required
                      oninvalid="this.setCustomValidity(`really, is there any state exist with such name!`)"
                      oninput="this.setCustomValidity('')"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="input-group">
                  <div class="input-field" style="width: 100%">
                    <input
                      id="00NHs00000mxShw"
                      maxlength="70"
                      name="00NHs00000mxShw"
                      size="20"
                      placeholder="Institute Name"
                      type="text"
                      class="form-control"
                      required
                      oninvalid="this.setCustomValidity(`Oops! Every great institute has a name. Maybe try yours, it could be legendary!`)"
                      oninput="this.setCustomValidity('')"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="00NHs00000mxSi9">Institute Type</label>
                  <select
                    style="height: 200px"
                    class="form-control"
                    id="00NHs00000mxSi9"
                    multiple="multiple"
                    required
                    oninvalid="this.setCustomValidity(`pick one, it's free!`)"
                    oninput="this.setCustomValidity('')"
                    name="00NHs00000mxSi9"
                    title="Institute Type"
                  >
                    <option value="K-12 Coaching">K-12 Coaching</option>
                    <option value="IIT">IIT</option>
                    <option value="NEET">NEET</option>
                    <option value="IAS">IAS</option>
                    <option value="UPSC">UPSC</option>
                    <option value="Medical Exams Coaching">
                      Medical Exams Coaching
                    </option>
                    <option value="Dance">Dance</option>
                    <option value="Singing">Singing</option>
                    <option value="Yoga">Yoga</option>
                    <option value="Skating">Skating</option>
                    <option value="Swimming">Swimming</option>
                    <option value="Badminton">Badminton</option>
                  </select>
                </div>
              </div>

              <!--  ----------------------------------------------------------------------  -->
              <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
              <!--  these lines if you wish to test in debug mode.                          -->
              <!--  <input type="hidden" name="debug" value=1>                              -->
              <!--  <input type="hidden" name="debugEmail"                                  -->
              <!--  value="himanshu_16986@yahoo.in">                                        -->
              <!--  ----------------------------------------------------------------------  -->

              <input
                style="
                  background-color: #464eb8;
                  text-align: center;
                  width: 100%;
                "
                class="btn btn-primary"
                type="submit"
                name="submit"
              />
            </form>
          </div>
        </div>
        <!-- <div
          class="desktop-only col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInDown animated join-us__media"
          data-wow-duration="500ms"
          data-wow-delay="300ms"
        >
          <img class="w-100" src="../../assets/images/join-us.jpg" alt="Join Us" />
        </div> -->
      </div>
    </div>
  </section>

  <!-- <section class="about">
    <div class="container">
      <div class="row mb50">
        <div
          class="sec-title text-center mb50 wow fadeInDown animated"
          data-wow-duration="500ms"
        ></div>

        <div
          class="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInLeft animated"
          data-wow-duration="500ms"
        >
          <img
            style="
              width: 100%;
              height: auto;
              margin-top: 80px;
              margin-bottom: 24px;
            "
            src="../../assets/images/vision.jpg"
            alt="Vision"
          />
        </div>
        <div
          class="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInLeft animated"
          data-wow-duration="500ms"
        >
          <div class="contact-address">
            <div
              class="sec-title text-center mb50 wow fadeInDown animated"
              data-wow-duration="500ms"
            >
              <h2>A Vision for Better Education</h2>
            </div>

            <p style="font-size: 15px; margin-bottom: 8px">
              We believe that teachers are the true architects of the future.
              Shikshatam is committed to providing educators with the tools,
              resources, and platforms they need to excel in their careers.
            </p>

            <p style="font-size: 15px; margin-bottom: 8px">
              Our goal is to empower teachers to innovate in their teaching
              methods, inspire their students with the right information, and
              make a lasting impact on their academic journey.
            </p>

            <p style="font-size: 15px; margin-bottom: 8px">
              By providing robust support to educators, we ensure that students
              have access to the best resources and mentorship, enabling them to
              reach their highest potential.
            </p>

            <p style="font-size: 15px; margin-bottom: 8px">
              By encouraging these relationships, we open doors for students by
              introducing them to outstanding teachers who can mentor and assist
              them in realising their goals.
            </p>

            <p style="font-size: 16px">
              Shikshatam envisions a future where every teacher has the
              opportunity to grow professionally and every student has access to
              the highest quality education. By bridging the gap between
              teachers and students, we aim to create a robust educational
              ecosystem that fosters continuous learning and growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="how clearfix">
    <div class="container">
      <div class="row">
        <div class="sec-title text-center mb50">
          <h2>How We Work</h2>
          <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
        </div>

        <div class="sec-sub-title text-center">
          <p>
            We make sure the onboarding process with Shikshatam is simple, safe,
            and secure.
          </p>
        </div>

        <div class="how__wrapper">
          <div class="col-md-4 how__card">
            <div class="how__item">
              <div class="how__icon">
                <img src="../../assets/icons/contact-form.png" alt="Step 1: Register" />
              </div>

              <div class="how__desc">
                <h3>Step 1: Register</h3>
                <p>
                  Institute/ Teacher needs to fill in the registration form by
                  logging into www.shikhatam.com and clicking on the “Register”
                  button.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 how__card">
            <div class="how__item">
              <div class="how__icon">
                <img src="../../assets/icons/acceptance.png" alt="Step 2: Review" />
              </div>

              <div class="how__desc">
                <h3>Step 2: Review</h3>
                <p>
                  The Shikshatam customer support team will review the
                  registration form and activate the institute and the institute
                  admin access.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 how__card">
            <div class="how__item">
              <div class="how__icon">
                <img src="../../assets/icons/email.png" alt="Step 3: Ready" />
              </div>

              <div class="how__desc">
                <h3>Step 3: Ready</h3>
                <p>
                  The institute admin will receive an SMS from SITPLT with the
                  user id and password to login to the portal. Also links for
                  video tutorial are sent on WhatsApp to the institute
                  administrator to complete setup.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="features" class="features">
    <div class="container">
      <div class="row">
        <div
          class="sec-title text-center mb50 wow bounceInDown animated"
          data-wow-duration="500ms"
        >
          <h2>Why Shikshatam</h2>
          <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
        </div>

        <div class="why__wrapper">
          <div
            class="col-md-4 wow fadeInRight why__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
          >
            <div class="why__item">
              <div class="why__icon">
                <img src="../../assets/images/portfolio.jpeg" alt="Automated Portfolio Creation" />
                <!-- <i class="fa fa-graduation-cap fa-2x"></i> -->
              </div>

              <div class="why__desc">
                <h3>Automated Portfolio Creation</h3>
                <p>
                  We offer automated portfolio creation tools designed to boost
                  sales and enhance marketing efforts for educational
                  institutions.
                </p>
              </div>
            </div>
          </div>

          <!-- service item -->
          <div
            class="col-md-4 wow fadeInUp why__card"
            data-wow-duration="500ms"
            data-wow-delay="500ms"
          >
            <div class="why__item">
              <div class="why__icon">
                <img src="../../assets/images/rapid-deployment.jpeg" alt="Rapid Deployment" />
                <!-- <i class="fa fa-user fa-2x"></i> -->
              </div>

              <div class="why__desc">
                <h3>Rapid Deployment</h3>
                <p>
                  Our user-friendly portal and mobile application can be fully
                  configured for your institute in just 30 minutes, ensuring a
                  seamless setup process.
                </p>
                <!-- <p>
                  Shikshatam App will help save precious study time of students
                  by moving away from Whatsapp messaging. Shikshatam will help
                  in uplifting the marketing and sales for the teachers to help
                  reach wider audience.
                </p> -->
              </div>
            </div>
          </div>
          <!-- end service item -->

          <!-- service item -->
          <div
            class="col-md-4 wow fadeInRight why__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
          >
            <div class="why__item">
              <div class="why__icon">
                <img src="../../assets/images/cloud-based.jpeg" alt="Pioneering Cloud-Based Solutions" />
                <!-- <i class="fa fa-graduation-cap fa-2x"></i> -->
              </div>

              <div class="why__desc">
                <h3>Pioneering Cloud-Based Solutions</h3>
                <p>
                  We are the first company in India to offer a cloud-based
                  education management platform accompanied by a mobile
                  application, provided at zero development cost.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInLeft why__card"
            data-wow-duration="500ms"
          >
            <div class="why__item">
              <div class="why__icon">
                <img src="../../assets/images/central-database.jpeg" alt="Centralized Data Management" />
                <!-- <i class="fa fa-institution fa-2x"></i> -->
              </div>

              <div class="why__desc">
                <h3>Centralized Data Management</h3>
                <p>
                  Our platform features a centrally managed database capable of
                  maintaining historic data, empowering teachers to build and
                  enhance their personal brand.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInLeft why__card"
            data-wow-duration="500ms"
          >
            <div class="why__item">
              <div class="why__icon">
                <img src="../../assets/images/advanced-analytics.jpeg" alt="Advanced Analytics and Reporting" />
                <!-- <i class="fa fa-institution fa-2x"></i> -->
              </div>

              <div class="why__desc">
                <h3>Advanced Analytics and Reporting</h3>
                <p>
                  Our platform provides sophisticated reporting and analytics on
                  student performance, enabling educators to identify areas for
                  improvement and tailor their teaching strategies accordingly.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInLeft why__card"
            data-wow-duration="500ms"
          >
            <div class="why__item">
              <div class="why__icon">
                <img src="../../assets/images/data_privacy.jpeg" alt="Data Protection And Privacy" />
                <!-- <i class="fa fa-institution fa-2x"></i> -->
              </div>

              <div class="why__desc">
                <h3>Data Protection And Privacy</h3>
                <p>
                  We prioritize data protection and privacy, ensuring that all
                  information shared on our platform is securely stored and
                  handled with the utmost confidentiality. Our robust security
                  measures safeguard sensitive data, giving educators peace of
                  mind while using our services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="works" class="works clearfix">
    <div class="container">
      <div class="row">
        <div class="sec-title text-center mb50">
          <h2>We Are Family</h2>
          <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
        </div>

        <div class="sec-sub-title text-center">
          <p>
            We are a one-of-a-kind family, helping one another learn, grow, and
            succeed. We are serious about driving educational success and
            creating positive change, but we also enjoy the journey together.
            Our founders launched Shikshatam with a commitment to empowering
            teachers. Today, we continue this mission by dedicating our
            resources, technology, and time to creating positive change for
            educators.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="features" class="features">
    <div class="container">
      <div class="row">
        <div
          class="sec-title text-center mb50 wow bounceInDown animated"
          data-wow-duration="500ms"
        >
          <h2>What Brings Us Together Guides Us Forward</h2>

          <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
        </div>

        <div class="features__wrapper">
          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
          >
            <div class="service-item">
              <div class="features__icon">
                <i class="fa fa-graduation-cap fa-2x"></i>
              </div>

              <div class="service-desc">
                <h3>Innovation</h3>
                <p>
                  We develop products based on user feedback, providing
                  continual technology updates and new initiatives to give
                  educators and students a competitive advantage.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInLeft features__card"
            data-wow-duration="500ms"
          >
            <div class="service-item">
              <div class="features__icon">
                <i class="fa fa-institution fa-2x"></i>
              </div>

              <div class="service-desc">
                <h3>Equality</h3>
                <p>
                  Everyone deserves equal opportunities. We value diverse
                  perspectives, which fuel innovation and deepen connections
                  within our community.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInUp features__card"
            data-wow-duration="500ms"
            data-wow-delay="500ms"
          >
            <div class="service-item">
              <div class="features__icon">
                <i class="fa fa-user fa-2x"></i>
              </div>

              <div class="service-desc">
                <h3>Teacher and Student Success</h3>
                <p>
                  When our teachers and students succeed, we succeed. We
                  innovate and expand our offerings to help all our users
                  achieve extraordinary outcomes.
                </p>
                <!-- <p>
                Shikshatam App will help save precious study time of students
                by moving away from Whatsapp messaging. Shikshatam will help
                in uplifting the marketing and sales for the teachers to help
                reach wider audience.
              </p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInLeft features__card"
            style="margin-top: 18px"
            data-wow-duration="500ms"
          >
            <div class="service-item">
              <div class="features__icon">
                <i class="fa fa-institution fa-2x"></i>
              </div>

              <div class="service-desc">
                <h3>Sustainability</h3>
                <p>
                  We are devoted to taking on ambitious leadership roles in the
                  field of education, tackling obstacles straight away, and
                  creating an environment that supports learning in a
                  sustainable way.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <div class="features__icon">
                <i class="fa fa-graduation-cap fa-2x"></i>
              </div>

              <div class="service-desc">
                <h3>Trust</h3>
                <p>
                  We act as trusted advisors to educators and students, ensuring
                  transparency, security, and integrity in all our interactions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="contact" class="contact" style="margin-bottom: 50px">
    <div class="container">
      <div class="row">
        <div
          class="sec-title text-center mb50 wow fadeInDown animated"
          data-wow-duration="500ms"
        >
          <h2>Contact Us</h2>
          <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
        </div>

        <div class="col-md-12 text-center">
          <p style="font-size: 16px">
            <strong><i class="fa fa-map-marker"></i> Address:</strong>
            Shikshatam IT Private Limited , workdesq , 649, 650, Main Rd, above
            Lenskart Showroom, Sector A, Mahalaxmi Nagar
            <br /><br />

            <strong onclick="window.open('mailto:support@shikshatam.com')"
              ><i class="fa fa-envelope"></i> Email:</strong
            >
            support&#64;shikshatam.com
            <br /><br />

            <strong onclick="window.location='tel:+919179328236'"
              ><i class="fa fa-phone"></i> Phone:</strong
            >
            (+91) 91793-28236
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="facts" class="facts">
    <div class="parallax-overlay">
      <div class="container">
        <div id="map_canvas"></div>
      </div>
    </div>
  </section>

  <!-- Editorials -->
  <section id="features" class="features">
    <div class="container">
      <div class="row">
        <div
          class="sec-title text-center mb50 wow bounceInDown animated"
          data-wow-duration="500ms"
        >
          <h2>Editorials</h2>
          <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
        </div>

        <div class="features__wrapper">
          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-book fa-2x"></i>
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/home-tutors-in-indore"
                    class="text-decoration-none"
                    title="Home Tutors In Indore"
                    >Home Tutors In Indore</a
                  >
                </h3>
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInLeft features__card"
            data-wow-duration="500ms"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-users fa-2x"></i>
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/tutoring-classes"
                    class="text-decoration-none"
                    title="Tutoring Classes"
                    >Tutoring Classes</a
                  >
                </h3>
                <!-- <p>The Ultimate Guide to Finding the Best Tutoring Classes</p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInUp features__card"
            data-wow-duration="500ms"
            data-wow-delay="500ms"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-rocket fa-2x"></i> 
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/improve-performance"
                    class="text-decoration-none"
                    title="Improve Performance"
                    >Improve Performance</a
                  >
                </h3>
                <!-- <p>Top Strategies to Improve Academic Performance</p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInLeft features__card"
            style="margin-top: 18px"
            data-wow-duration="500ms"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-chalkboard-teacher fa-2x"></i> 
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/coaching-skills"
                    class="text-decoration-none"
                    title="Coaching Skills"
                    >Coaching Skills</a
                  >
                </h3>
                <!-- <p>Essential Coaching Skills for Effective Teaching</p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-money-bill-wave fa-2x"></i> 
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/tuition-fees"
                    class="text-decoration-none"
                    title="Tuition Fees"
                    >Tuition Fees</a
                  >
                </h3>
                <!-- <p>Understanding Tuition Fees: What You Need to Know</p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-briefcase fa-2x"></i> 
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/career-development"
                    class="text-decoration-none"
                    title="Career Development"
                    >Career Development</a
                  >
                </h3>
                <!-- <p>Navigating Career Development: Strategies for Success</p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-sitemap fa-2x"></i> 
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/types-of-coaching"
                    class="text-decoration-none"
                    title="Types Of Coaching"
                    >Types Of Coaching</a
                  >
                </h3>
                <!-- <p>Exploring Different Types of Coaching: Finding the Right Fit</p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-dollar-sign fa-2x"></i> 
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/exam-preparation"
                    class="text-decoration-none"
                    title="Exam Preparation"
                    >Exam Preparation</a
                  >
                </h3>
                <!-- <p>Exam Preparation Tips | Shikshatam</p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-life-ring fa-2x"></i>
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/life-coaching"
                    class="text-decoration-none"
                    title="Life Coaching"
                    >Life Coaching</a
                  >
                </h3>
                <!-- <p>Life Coaching | Shikshatam</p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-graduation-cap fa-2x"></i> 
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/executive-coaching"
                    class="text-decoration-none"
                    title="Executive Coaching"
                    >Executive Coaching</a
                  >
                </h3>
                <!-- <p>Executive Coaching | Shikshatam</p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-star fa-2x"></i>
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/good-coaches"
                    class="text-decoration-none"
                    title="Good Coaches"
                    >Good Coaches</a
                  >
                </h3>
                <!-- <p>Qualities of Good Coaches | Shikshatam</p> -->
              </div>
            </div>
          </div>
          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-star fa-2x"></i>
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/work-performance"
                    class="text-decoration-none"
                    title="Work Performance"
                    >Work Performance</a
                  >
                </h3>
                <!-- <p>Qualities of Good Coaches | Shikshatam</p> -->
              </div>
            </div>
          </div>
          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-star fa-2x"></i>
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/line-management"
                    class="text-decoration-none"
                    title="Line Management"
                    >Line Management</a
                  >
                </h3>
                <!-- <p>Qualities of Good Coaches | Shikshatam</p> -->
              </div>
            </div>
          </div>
          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-star fa-2x"></i>
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/coaching-classes"
                    class="text-decoration-none"
                    title="Coaching Classes"
                    >Coaching Classes</a
                  >
                </h3>
                <!-- <p>Qualities of Good Coaches | Shikshatam</p> -->
              </div>
            </div>
          </div>
          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-star fa-2x"></i>
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/coaching-relationship"
                    class="text-decoration-none"
                    title="Coaching Relationship"
                    >Coaching Relationship</a
                  >
                </h3>
                <!-- <p>Qualities of Good Coaches | Shikshatam</p> -->
              </div>
            </div>
          </div>
          <div
            class="col-md-4 wow fadeInRight features__card"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
            style="margin-top: 18px"
          >
            <div class="service-item">
              <!-- <div class="features__icon">
                  <i class="fa fa-star fa-2x"></i>
                </div> -->
              <div class="service-desc">
                <h3>
                  <a
                    href="article/mentoring-relationship"
                    class="text-decoration-none"
                    title="Mentoring Relationship"
                    >Mentoring Relationship</a
                  >
                </h3>
                <!-- <p>Qualities of Good Coaches | Shikshatam</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

    <!-- Popular Cities Section -->
    @if(showPopularCities){
      <section id="popular-citites"  style="margin-bottom: 50px">
        <div class="container">
          <div class="row">
            <div
              class="sec-title text-center mb50 wow fadeInDown animated"
              data-wow-duration="500ms"
            >
              <h2>Popular Cities</h2>
              <div class="devider"><i class="fa fa-heart-o fa-lg"></i></div>
            </div>
    
            <div class="col-md-12 ">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                @for (cityObj of popularCitiesItems; track cityObj._id; let  i = $index) {  
                  <button class="nav-link" [class]="activeCityTab === cityObj._id ? 'active' : ''"  type="button" role="tab" (click)="onClickCityTab(cityObj._id)">{{cityObj.name}}</button>

                }
              </div>

              <div class="tab-content" id="nav-tabContent">
                @for (cityObj of popularCitiesItems; track cityObj._id; let  i = $index) {  
                  <div class="tab-pane fade" [class]="activeCityTab === cityObj._id ? 'show active' : ''"  role="tabpanel"  tabindex="0">
                    @for (obj of cityObj.links; track obj; let  i = $index) {  
                      <span >
                        <a 
                        class="mx-1"
                          [routerLink]="obj.route" 
                          [queryParams]="obj.queryParams"
                        > 
                          {{obj.text }} 
                        </a> 
                        {{i != cityObj.links.length-1 ? '|': ''}}
                      </span>
                    }
                  </div>
                }
              </div>


             
            </div>
          </div>
        </div>
      </section>
    }
</main>
<app-main-footer></app-main-footer>
