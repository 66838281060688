<div class="institute">
  <div class="institute__media">
    <img
      class="w-100"
      [src]="
        institute.instituteLogo
          ? institute.instituteLogo
          : '../../../assets/icons/university.png'
      "
      [alt]="institute.instituteName"
    />
    <!-- <img [src]="mediaUrl + institute.logo" alt="" /> -->
  </div>
  <div class="institute__info">
    <div class="institute__info--name">
      {{ institute.instituteName | titlecase }}
      <span *ngIf="institute.currentStage === 'VERIFIED'" class="verified-badge"
        >Verified</span
      >
    </div>
    <div class="institute__info--teachers" *ngIf="institute.teachersCount">
      {{ institute.teachersCount }} Teachers
    </div>
    <div class="institute__info--experience">
      {{ institute.experienceText }}
    </div>
    <div class="institute__info--address">
      {{ institute.instituteAddress }}
    </div>
    <div (click)="onGetDirection(institute)" class="institute__info--address">
      <span style="font-size: larger; font-weight: bold; cursor: pointer">
        {{ institute.distanceInText }}
      </span>
    </div>
  </div>
  <div class="institute__action">
    <button class="btn btn-primary" (click)="openContactModal(institute._id)">
      Contact
    </button>
    <a class="btn btn-outline-primary" [href]="'/institute/'+institute.urlCode">
      Details
    </a>
    <button
      class="btn btn-secondary"
      style="background-color: #478c42"
      (click)="onGetDirection(institute)"
    >
      Directions
    </button>
  </div>
</div>
