import { NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListingComponent } from './listing/listing.component';
import { HttpClientModule } from '@angular/common/http';
import { DetailComponent } from './detail/detail.component';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
  NgOptimizedImage,
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { CheckoutComponent } from './checkout/checkout.component';
import { SafePipe } from './utils/safe.pipe';
import { TermsComponent } from './shared/terms/terms.component';
import { PrivacyComponent } from './shared/privacy/privacy.component';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ListingComponent,
    DetailComponent,
    RegisterComponent,
    CheckoutComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgbModule,
    ImageCropperComponent,
    NgHttpLoaderModule.forRoot(),
    SafePipe,
    NgOptimizedImage,
    TermsComponent,
    PrivacyComponent,
    NgSelectModule,
  ],

  // providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
  providers: [provideClientHydration()],
})
export class AppModule {}
