import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ArticlesService, Article } from '../services/article.service';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-article',
  imports: [CommonModule, SharedModule],
  standalone: true,
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {
  article: Article | undefined;
  private subscription: Subscription = new Subscription();

  constructor(
    private articlesService: ArticlesService,
    private route: ActivatedRoute,
    private meta: Meta,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.paramMap.subscribe(params => {
        const weburl = params.get('weburl');
        this.subscription.add(
          this.articlesService.getArticleByWebUrl(weburl).subscribe((article) => {
            this.article = article;
            if (article) {
              // Update title and meta tags dynamically
              const pageTitle = article.title || 'Finding the Best Home Tutors in Indore: A Comprehensive Guide';
              const pageDescription = article.description || 'Find the best home tutors in Indore to help your child excel in academics. Learn the benefits of home tutoring and tips for finding the perfect tutor.';
              const pageKeywords = article.keywords || 'home tutors in Indore, best home tutors Indore, private tutors Indore, home tuition Indore';
              console.log('====================================');
              console.log(article.title);
              console.log('====================================');
              this.titleService.setTitle(pageTitle);
              this.meta.updateTag({ name: 'description', content: pageDescription });
              this.meta.updateTag({ name: 'keywords', content: pageKeywords });
              this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
            }
          })
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
