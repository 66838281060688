import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  createInstituteLead,
  getAllInstitutes,
  getAllInstituteTypes,
  getCityByInstitute,
} from '../utils/api.constant';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonService {
  _http = inject(HttpClient);

  constructor() {
    this.fetchInstituteTypes();
  }

  fetchCityByInsitute(): Observable<CityByInstituteResponse> {
    return this._http.get<CityByInstituteResponse>(getCityByInstitute);
  }

  fetchInstituteTypes(params: any = {}): Observable<InsituteTypeFetchResponse> {
    return this._http.get<InsituteTypeFetchResponse>(getAllInstituteTypes, {params});
  }

  fetchAllInstitutes(params: any): Observable<any> {
    return this._http.get<any>(getAllInstitutes, { params });
  }

  submitInstituteLead(body: any): Observable<any> {
    return this._http.post(createInstituteLead, body);
  }
}

export interface CityByInstituteResponse {
  success: boolean;
  message: string;
  data: CitiesByInstitute[];
}

export interface CitiesByInstitute {
  _id: string;
  id: number;
  name: string;
  state_id: number;
  state_code: string;
  state_name: string;
  country_id: number;
  country_code: string;
  country_name: string;
  latitude: number;
  longitude: number;
  wikiDataId: string;
}

export interface InsituteTypeFetchResponse {
  success: boolean;
  message: string;
  data: InstituteTypes[];
}

export interface InstituteTypes {
  _id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  isPopular?: boolean;
}
