<div class="batch">
  <div class="batch__info row">
    <div class="col-5 batch__info--name">
      {{ batch.combinedBatchName | titlecase }}
    </div>
    <div
      *ngIf="batch.startTime && batch.endTime"
      class="col-4 batch__info--date"
    >
      <span>
        Timing: {{ tConvert(batch.startTime) }} to
        {{ tConvert(batch.endTime) }}
      </span>
    </div>
    <div *ngIf="batch.students.length" class="col-3 batch__info--students">
      <span>{{ batch.students.length }} Students</span>
    </div>
  </div>
</div>
