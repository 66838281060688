import { environment } from '../../environments/environment.development';

export const aggregateInstitutes =
  environment.BASE_URL + '/api/v1/aggregator/institute';
export const getCityByInstitute =
  environment.BASE_URL + '/api/v1/get/city/by/institute';
export const getAllInstituteTypes =
  environment.BASE_URL + '/api/v1/all/instituteType';
export const getAllInstitutes =
  environment.BASE_URL + '/api/v1/get/all/institute';
export const getDetailInstituteByUrlCode =
  environment.BASE_URL + '/api/v1/aggregator/institute/detail';
export const createInstituteLead =
  environment.BASE_URL + '/api/v1/create/institute/lead';
