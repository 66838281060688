import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormErrorService } from '../shared/services/form-error.service';
import { FormService } from '../shared/services/form.service';
import { ToastService } from '../shared/services/toast.service';
import { InstituteService } from '../shared/services/institute.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { StateInterface } from '../shared/model/state.model';
import { InstituteTypeInterface } from '../shared/model/institute.model';
import { ClassesInteface } from '../shared/model/classes.model';
import { CityInterface } from '../shared/model/city.model';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Router } from '@angular/router';
import { data } from 'jquery';
declare var google: any; // Declare google for TypeScript
@Component({
  selector: 'app-register',
  standalone: false,
  // imports: [],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  host: { ngSkipHydration: 'true' },
})
export class RegisterComponent implements AfterViewInit, OnInit {
  _modalService = inject(NgbModal);
  _formBuilder = inject(FormBuilder);
  _formErrorService = inject(FormErrorService);
  _formService = inject(FormService);
  _toastService = inject(ToastService);
  instituteService = inject(InstituteService);
  _spinnerService = inject(SpinnerVisibilityService);
  _router = inject(Router);
  instituteForm!: FormGroup;
  subscriptionForm!: FormGroup;
  states: StateInterface[] = [];
  cities: CityInterface[] = [];
  localities: [] = [];
  classes: ClassesInteface[] = [];
  instituteTypes: any[] = [];
  selectedStateForUser = '';
  selectedCityForUser = '';
  logoImagePreview: any;
  logoFile: File | null = null;
  @ViewChild('cropper') cropper: any;
  @ViewChild('cropContent') cropContent: any;
  @ViewChild('confrimationContent') confrimationContent: any;
  @ViewChild('subscriptionContent') subscriptionContent: any;
  @ViewChild('cropContentForMultiple') cropContentWithIndex: any;
  croppedImage: any = '';
  croppedImageWithIndexWorking: string[] = [];
  croppedImagesWithIndex: string[] = [];
  sendFormImages: any = [];
  modalRef!: NgbModalRef;
  selectedCropIndex: number = 0;
  currentCropedFile: any;
  selectedBlobFile: any;
  createUserAlsoFC = new FormControl(true);
  map: any;
  marker: any;
  defaultLocation = { lat: 22.7196, lng: 75.8577 }; // Indore's lat and lng
  isLoadingLocalities = false;

  ngOnInit(): void {
    this.fetchMasterData();
    this.buildForm();
    this.buildSubscriptionForm();
  }

  loadMap() {
    // Ensure the Google Maps API is loaded
    if (typeof google !== 'undefined' && google) {
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 15,
        center: this.defaultLocation,
      });

      this.marker = new google.maps.Marker({
        position: this.defaultLocation,
        map: this.map,
        draggable: true,
      });

      // Update marker position on drag end
      google.maps.event.addListener(this.marker, 'dragend', (event: any) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        this.instituteForm.get('lat')?.setValue(lat);
        this.instituteForm.get('lng')?.setValue(lng);
      });

      // Autocomplete for search input
      const input = document.getElementById('searchInput') as HTMLInputElement;
      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.bindTo('bounds', this.map);

      // Update marker position on place selection
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          return;
        }
        this.map.setCenter(place.geometry.location);
        this.marker.setPosition(place.geometry.location);

        this.instituteForm.get('lat')?.setValue(place.geometry.location.lat());
        this.instituteForm.get('lng')?.setValue(place.geometry.location.lng());
      });
    } else {
      this._toastService.showError('Maps not loaded.');
    }
  }

  locateMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.instituteForm.get('lat')?.setValue(position.coords.latitude);
          this.instituteForm.get('lng')?.setValue(position.coords.longitude);

          this.map.setCenter(pos);
          this.marker.setPosition(pos);
        },
        null,
        { enableHighAccuracy: true }
      );
    } else {
      this._toastService.showError(
        'Geolocation is not supported by this browser.'
      );
    }
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
    this.loadMap();
  }

  openModal(content: TemplateRef<any>, size?: string) {
    this.modalRef = this._modalService.open(content, {
      size: size ? size : 'md',
    });
  }

  buildForm() {
    this.instituteForm = this._formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      adminEmail: new FormControl('', [Validators.email]),
      adminPhone: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[5-9]\d{9}$/),
      ]),
      adminAddress: new FormControl('', Validators.required),
      adminState: new FormControl('', Validators.required),
      adminCity: new FormControl('', Validators.required),
      adminPostalCode: new FormControl('', Validators.required),
      dob: new FormControl(''),
      type: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      website: new FormControl('', [
        Validators.pattern(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w.-](?:\/[\w.-]*)?$/
        ),
      ]),
      email: new FormControl('', Validators.email),
      phone: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      locality: new FormControl('', Validators.required),
      postalCode: new FormControl('', Validators.required),
      classes: new FormControl('', Validators.required),
      salesRepCode: new FormControl(''),
      logoInput: new FormControl(null),
      isInstituteAddressSame: new FormControl(false),
      lat: new FormControl(''),
      lng: new FormControl(''),
      qualification: new FormControl(''),
      skills: new FormControl(''),
      experience: new FormControl(''),
    });
  }

  onSearchLocalities(searchTerm: any) {
    console.log(searchTerm.term);

    this.isLoadingLocalities = true;

    this.instituteService
      .getLocalities(this.instituteForm.value.city, searchTerm.term)
      .subscribe({
        next: (_) => {
          this.localities = _.data;
          this.isLoadingLocalities = false;
        },
        error: (err) => {
          this._toastService.showError(err);
          this.isLoadingLocalities = false;
        },
      });
  }

  buildSubscriptionForm() {
    this.subscriptionForm = this._formBuilder.group({
      plan: new FormControl('PLAN1'),
    });
  }

  fetchMasterData(): void {
    this.instituteService.getInstituteTypes().subscribe((data) => {
      this.instituteTypes = data.data;
    });

    this.instituteService.getStates().subscribe((data) => {
      this.states = data.data;
    });

    this.instituteService.getClasses().subscribe((data) => {
      this.classes = data.data;
    });
  }

  onStateChange(ev: any): void {
    this.instituteService
      .getCitiesByStateId(ev.target.value)
      .subscribe((data) => {
        this.cities = data.data;
      });
  }

  onCityChange(ev: any): void {
    this.isLoadingLocalities = true;
    this.instituteService.getLocalities(ev.target.value).subscribe({
      next: (data) => {
        this.isLoadingLocalities = false;
        this.localities = data.data;
      },
      error: (_) => {
        this.isLoadingLocalities = false;
        this._toastService.showError(_);
      },
    });
  }

  onLocalityChange(e: any) {}

  getFileDetail(event: any): void {
    this._spinnerService.show();
    try {
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this._spinnerService.hide();
          this.logoImagePreview = e.target.result;
          this.openCropModal();
        };
        reader.readAsDataURL(event.target.files[0]); // to trigger onload
      }
      if (event.target.files.length === 1) {
        const file = event.target.files[0] as File;
      }
    } catch (e) {
      this._spinnerService.hide();
    }
  }

  openCropModal() {
    this.modalRef = this._modalService.open(this.cropContent);
  }

  onCropImage(event?: any): void {
    this.convertBlobToBase64(event.blob);
  }

  convertBlobToBase64(blob: Blob): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.croppedImage = reader.result as string;
    };
    reader.readAsDataURL(blob);
    const extension = 'png';
    const autoGeneratedFileName = this.generateUniqueFileName(extension);
    this.logoFile = new File([blob], autoGeneratedFileName, {
      type: blob.type,
    });
  }
  generateUniqueFileName(extension: any) {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(7);
    return `${timestamp}-${randomString}.${extension}`;
  }
  saveCroppedImage(): void {
    if (this.croppedImage) {
      this.modalRef.dismiss();
    }
  }

  removeLogo(): void {
    this.logoImagePreview = null;
    this.logoFile = null;
    this.croppedImage = null;
  }
  // for multiple uploads
  getFileDetailwithIndex(event: any, index: number): void {
    try {
      if (event.target.files && event.target.files[0]) {
        this._spinnerService.show();
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this._spinnerService.hide();
          this.croppedImagesWithIndex[index] = e.target.result;
          this.croppedImageWithIndexWorking[index] = e.target.result;
          this.sendFormImages.push({ blob: event.target.files[0] });

          // const extension = 'png';
          // const autoGeneratedFileName = this.generateUniqueFileName(extension);
          // this.currentCropedFile = new File([blob], autoGeneratedFileName, {
          //   type: blob.type,
          // });
          // this.openCropModalwithIndex(index);
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        if (this.croppedImagesWithIndex[index])
          this.croppedImagesWithIndex.splice(index, 1);
        this.croppedImageWithIndexWorking.splice(index, 1);
        this.sendFormImages.splice(index, 1);
      }
    } catch (e) {
      this._spinnerService.hide();
    }
  }

  removeLogowithIndex(index: number): void {
    for (let i = 0; i < this.croppedImagesWithIndex.length; i++) {
      if (i === index) {
        !this.croppedImagesWithIndex[i] || '';
      }
    }
    for (let i = 0; i < this.sendFormImages.length; i++) {
      if (i === index) {
        !this.sendFormImages[i] || '';
      }
    }
    for (let i = 0; i < this.croppedImageWithIndexWorking.length; i++) {
      if (i === index) {
        !this.croppedImageWithIndexWorking[i] || '';
      }
    }
  }

  openCropModalwithIndex(index: number) {
    this.selectedCropIndex = index;
    this.modalRef = this._modalService.open(this.cropContentWithIndex);
  }

  onCropImagewithIndex(event: any, index: number): void {
    this.convertBlobToBase64withIndex(event.blob, index);
  }

  convertBlobToBase64withIndex(blob: Blob, index: number): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.croppedImageWithIndexWorking[index] = reader.result as string;
    };
    reader.readAsDataURL(blob);
    const extension = 'png';
    const autoGeneratedFileName = this.generateUniqueFileName(extension);
    this.currentCropedFile = new File([blob], autoGeneratedFileName, {
      type: blob.type,
    });
    this.selectedBlobFile = blob;
  }

  saveCroppedImagewithIndex(index: number): void {
    if (this.croppedImagesWithIndex[index]) {
      this.sendFormImages.push({ blob: this.selectedBlobFile });
      this.modalRef.dismiss();
    }
  }
  onOpenSubscriptionModal() {
    this._formService.markFormAsTouched(this.instituteForm);
    if (!this.instituteForm.valid) {
      this._toastService.showError(
        'Form is not valid. Please check the fields and try again.'
      );
      return;
    }
    if (!this.logoFile) {
      this._toastService.showError(
        'Please provide logo of your institute to proceed.'
      );
      return;
    }
    if (this.sendFormImages.length === 0) {
      this._toastService.showError(
        'Please provide at least one image of your institute.'
      );
      return;
    }
    this.openModal(this.subscriptionContent, 'xl');
  }
  onSubmit(): void {
    this._formService.markFormAsTouched(this.subscriptionForm);
    if (!this.instituteForm.valid) {
      this._toastService.showError(
        'Form is not valid. Please check the fields and try again.'
      );
      return;
    }

    const formData = new FormData();

    Object.keys(this.instituteForm.controls).forEach((key) => {
      if (this.instituteForm.get(key)?.value) {
        formData.append(key, this.instituteForm.get(key)?.value);
      }
    });

    if (this.logoFile) {
      formData.append('logo', this.logoFile);
    }

    if (this.sendFormImages.length > 0) {
      this.sendFormImages.forEach(
        (img: { blob: string | Blob }, index: any) => {
          formData.append(`files[${index}]`, img.blob);
        }
      );
    }

    this.instituteService.createInstitute(formData).subscribe({
      next: (response) => {
        this._toastService.showSuccess('Institute created successfully!');
        this.instituteForm.reset();
        this.modalRef.dismiss();
        this.removeLogo();
        this.croppedImagesWithIndex = [];
        this.sendFormImages = [];
        this.croppedImageWithIndexWorking = [];
        if (this.subscriptionForm.value.plan !== 'PLAN1') {
          this.openModal(this.confrimationContent, 'md');
        } else {
          this._router.navigateByUrl('/checkout');
        }
      },
      error: (error) => {
        this._toastService.showError(
          'Failed to create institute. Please try again. Error is : ' + error
        );
      },
    });
  }

  onSameAddressChange(e: any) {
    if (e.target.checked) {
      this.instituteForm
        .get('email')
        ?.patchValue(this.instituteForm.value.adminEmail);

      this.instituteForm
        .get('phone')
        ?.patchValue(this.instituteForm.value.adminPhone);

      this.instituteForm
        .get('state')
        ?.patchValue(this.instituteForm.value.adminState);
      this.instituteForm
        .get('city')
        ?.patchValue(this.instituteForm.value.adminCity);
      this.instituteForm
        .get('postalCode')
        ?.patchValue(this.instituteForm.value.adminPostalCode);
      this.instituteForm
        .get('address')
        ?.patchValue(this.instituteForm.value.adminAddress);

      this.onCityChange({
        target: { value: this.instituteForm.value.adminCity },
      });
    } else {
      this.instituteForm.get('state')?.reset();
      this.instituteForm.get('city')?.reset();
      this.instituteForm.get('postalCode')?.reset();
      this.instituteForm.get('address')?.reset();
    }
  }

  onChangeCreateUserSwitch(e: any) {
    if (e.target.checked) {
      this.instituteForm.get('firstName')?.setValidators([Validators.required]);
      this.instituteForm.get('lastName')?.setValidators([Validators.required]);
      this.instituteForm
        .get('adminEmail')
        ?.setValidators([Validators.required, Validators.email]);
      this.instituteForm
        .get('adminPhone')
        ?.setValidators([
          Validators.required,
          Validators.pattern(/^[5-9]\d{9}$/),
        ]);
      this.instituteForm
        .get('adminAddress')
        ?.setValidators([Validators.required]);
      this.instituteForm
        .get('adminState')
        ?.setValidators([Validators.required]);
      this.instituteForm.get('adminCity')?.setValidators([Validators.required]);
      this.instituteForm
        .get('adminPostalCode')
        ?.setValidators([Validators.required]);
    } else {
      this.instituteForm.get('firstName')?.setValidators(null);
      this.instituteForm.get('lastName')?.setValidators(null);
      this.instituteForm.get('adminEmail')?.setValidators(null);
      this.instituteForm.get('adminPhone')?.setValidators(null);
      this.instituteForm.get('adminAddress')?.setValidators(null);
      this.instituteForm.get('adminState')?.setValidators(null);
      this.instituteForm.get('adminCity')?.setValidators(null);
      this.instituteForm.get('adminPostalCode')?.setValidators(null);
    }
  }
  onNavigateToHome() {
    this._modalService.dismissAll();
    this._router.navigateByUrl('/');
  }
  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }

  // MAPS logic
}
