import { Component, Input, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { InstituteListings } from 'src/app/services/listing.service';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-institute-card',
  standalone: false,
  templateUrl: './institute-card.component.html',
  styleUrl: './institute-card.component.scss',
  host: { ngSkipHydration: 'true' },
})
export class InstituteCardComponent {
  @Input() institute!: InstituteListings;
  @Input() searchMode!: 'BY_CITY' | 'LOCATE_ME' | 'BY_INSTITUTE';
  @Input() latitude!: number | undefined;
  @Input() longitude!: number | undefined;

  private _modalService = inject(NgbModal);
  private _router = inject(Router);
  private _toastService = inject(ToastService);

  openContactModal(instituteId: string) {
    const modalRef = this._modalService.open(ContactFormComponent);
    modalRef.componentInstance.instituteId = instituteId;
  }

  onViewDetails() {
    if (!this.institute.urlCode) {
      this._toastService.showError('No profile found');
      return;
    }
    this._router.navigateByUrl('/institute/' + this.institute.urlCode);
  }

  onGetDirection(institute: InstituteListings) {
    let url;
    if (this.searchMode == 'LOCATE_ME') {
      if (this.latitude == 0 || this.latitude?.toString() === '0') {
        this._toastService.showError('Location not avaialble');
        return;
      }
      url = `https://www.google.com/maps/dir/?api=1&origin=${this.latitude},${this.longitude}&destination=${institute.coordinates[1]},${institute.coordinates[0]}`;
    } else {
      if (institute.coordinates[1] == '0' || +institute.coordinates[1] == 0) {
        this._toastService.showError('Location not avaialble');
        return;
      }
      url = `https://www.google.com/maps/dir/?api=1&&destination=${institute.coordinates[1]},${institute.coordinates[0]}`;
    }
    window.open(url, '_blank');
  }
}
