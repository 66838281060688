import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { getDetailInstituteByUrlCode } from '../utils/api.constant';
import { environment } from 'src/environments/environment.development';
import { InstituteTypes } from './common.service';
import { ClassesInteface } from '../shared/model/classes.model';

@Injectable()
export class DetailService {
  _http = inject(HttpClient);
  httpParams = new HttpParams();

  fetchDetails(urlCode: string): Observable<InstituteByUrlCodeResponse> {
    this.httpParams = new HttpParams();
    this.httpParams = this.httpParams.append('urlCode', urlCode);

    return this._http
      .get<InstituteByUrlCodeResponse>(getDetailInstituteByUrlCode, {
        params: this.httpParams,
      })
      .pipe(
        map((_) => {
          if (_.data.experienceMin && _.data.experienceMax) {
            if (_.data.experienceMax === _.data.experienceMin)
              `${_.data.experienceMax} years experience`;
            else
              _.data.experienceText = `${
                _.data.experienceMin + '-' + _.data.experienceMax
              } years experience`;
          }
          if (
            _.data.institute.logo &&
            !_.data.institute.logo.includes(environment.BUCKET_URL)
          ) {
            _.data.institute.logo =
              environment.BUCKET_URL + _.data.institute.logo;
          }

          _.data.institute.gallery.forEach((gallery) => {
            if (!gallery.path.includes(environment.BUCKET_URL))
              gallery.path = environment.BUCKET_URL + gallery.path;
          });

          _.data.teachersData.forEach((teacher) => {
            if (
              teacher.profile &&
              !teacher.profile.includes(environment.BUCKET_URL)
            ) {
              teacher.profile = environment.BUCKET_URL + teacher.profile;
            }
          });

          return _;
        })
      );
  }
}

export interface InstituteByUrlCodeResponse {
  success: boolean;
  message: string;
  data: InstituteByUrlCode;
}

export interface InstituteByUrlCode {
  institute: Institute;
  teachersData: TeachersDatum[];
  experienceMin: number;
  batches: Batch[];
  experienceMax: number;

  experienceText?: string; // for ui
}

export interface Batch {
  _id: string;
  startTime: string;
  endTime: string;
  students: string[];
  combinedBatchName: string;
}

export interface TeachersDatum {
  _id: string;
  mergedName: string;
  subject?: string;
  skills: string;
  experience: number;
  qualification: string;
  profile?: string;
}

export interface Institute {
  isLeadModuleEnabled: boolean;
  _id: string;
  name: string;
  address: string;
  state: State;
  city: City;
  logo: string;
  postalCode: number;
  gallery: any[];
  briefInfo?: string;
  ytURL?: string;
  type: InstituteTypes[];
  classes: ClassesInteface[];
  location?: { coordinates: number[] };
}

export interface City {
  _id: string;
  id: number;
  name: string;
  state_id: number;
  state_code: string;
  state_name: string;
  country_id: number;
  country_code: string;
  country_name: string;
  latitude: number;
  longitude: number;
  wikiDataId: string;
}

export interface State {
  _id: string;
  id: number;
  name: string;
  country_id: number;
  country_code: string;
  country_name: string;
  state_code: string;
  type: string;
  latitude: number;
  longitude: number;
}
