import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../services/blogs.service';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-blogs',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  blog: any;

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogService
  ) {}

  ngOnInit(): void {
    const idParam = this.route.snapshot.paramMap.get('id');
    if (idParam) {
      const weburl = idParam;
      this.blogService.getBlogById(weburl).subscribe((data) => {
        this.blog = data;
      });
    } else {
      console.error('No blog ID found in the route');
    }
  }
}
