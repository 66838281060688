<div class="teacher">
  <div class="teacher__media">
    <img
      [src]="
        teacher.profile
          ? teacher.profile
          : '../../../assets/icons/teachers-neutral.png'
      "
      [alt]="teacher.mergedName"
    />
  </div>
  <div class="teacher__info">
    <div class="teacher__info--name">{{ teacher.mergedName | titlecase }}</div>
    <div *ngIf="teacher.experience" class="teacher__info--experience">
      {{ teacher.experience }} years experience
    </div>
    <div class="teacher__info--address">
      @for (item of teacher.skills; track $index) {
      <span>{{ item | titlecase }}</span>
      }
    </div>
    <div class="teacher__info--address">
      @for (item of teacher.qualification; track $index) {
      <span>{{ item | titlecase }}</span>
      }
    </div>
  </div>
  <!-- <div class="teacher__action">
    <button class="btn btn-primary">Contact</button>
  </div> -->
</div>
