<app-main-header></app-main-header>
<div class="container mt-5">
  <div class="row">
    <!-- Left Column for Articles -->
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-6 mb-4" *ngFor="let blog of blogs">
          <div class="card h-100 shadow-sm">
            <img
              [src]="blog.imageUrl"
              class="card-img-top"
              alt="{{ blog.title }}"
            />
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">{{ blog.title }}</h5>
              <p class="card-text flex-grow-1">{{ blog.summary }}</p>
              <p>{{blog.key}}</p>
              <div class="d-flex justify-content-between mt-1">
                <!-- <a
                  class="btn btn-primary btn-sm me-2"
                  [href]="blog.facebookLink"
                  target="_blank"
                >
                  <i class="fa fa-facebook me-1"></i> Share
                </a>
                <a
                  class="btn btn-info btn-sm me-2"
                  [href]="blog.linkedinLink"
                  target="_blank"
                >
                  <i class="fa fa-linkedin me-1"></i> Share
                </a> -->
                <button
                  class="btn btn-secondary btn-sm"
                  (click)="redirectToBlog(blog.weburl)"
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Column for Subscription and Follow -->
    <div class="col-md-4">
      <!-- Subscription Card -->
      <!-- <div class="card mb-4">
        <div class="card-body">
          <h5 class="card-title">Subscribe to Shikshatam Blog</h5>
          <p class="card-text">Find the Best Institute Near You</p>
          <form class="d-flex flex-column">
            <div class="mb-3">
              <input type="email" class="form-control" placeholder="abc@xyz.com">
            </div>
            <button type="submit" class="btn btn-primary w-100">
              Subscribe Now
            </button>
          </form>
        </div>
      </div> -->

      <!-- Follow Us Card -->
      <!-- <div class="card mb-4">
        <div class="card-body text-center">
          <h5 class="card-title">Follow us on Facebook</h5>
          <button class="btn btn-secondary mb-3">Follow us on Facebook</button>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">348,036 followers</h5>
              <p class="card-text">Follow Shikshatam</p>
              <a href="#" class="btn btn-primary">Follow Page</a>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Additional Resources Card -->
      <div class="card mb-4">
        <div class="card-body">
          <ul class="list-unstyled">
            <li>
              <i class="fa fa-chevron-right me-2"></i> Education Resources
            </li>
            <li><i class="fa fa-chevron-right me-2"></i> Teacher Training</li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Online Learning Platforms
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Best Educational Tools
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Professional Development
              for Teachers
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Innovative Teaching
              Methods
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Top Education Blogs
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Educational Technology
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> School Improvement
              Strategies
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Teacher Support Networks
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-main-footer></app-main-footer>
