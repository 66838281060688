import { Component, Input } from '@angular/core';
import { TeachersDatum } from 'src/app/services/detail.service';

@Component({
  selector: 'app-teacher-card',
  standalone: false,
  // imports: [],
  templateUrl: './teacher-card.component.html',
  styleUrl: './teacher-card.component.scss',
})
export class TeacherCardComponent {
  @Input() teacher!: TeachersDatum;
}
