import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FormErrorService {
  constructor() {}

  getErrorMessage(fieldName: string, errorName: string): string {
    switch (errorName) {
      case 'pattern':
        if (fieldName == 'mobileNumber') {
          return 'Invalid mobile number';
        }
        if (fieldName === 'email') {
          return 'Invalid email';
        }
        return 'Invalid value';
      case 'required':
        if (fieldName === 'firstName') {
          return 'Invalid first name';
        }
        if (fieldName === 'lastName') {
          return 'Invalid last name';
        }
        if (fieldName === 'userEmail') {
          return 'Invalid email';
        }
        if (fieldName === 'userPhone') {
          return 'Invalid mobile number';
        }
        if (fieldName === 'userAddress') {
          return 'Invalid user address';
        }
        if (fieldName === 'userState') {
          return 'Invalid user state';
        }
        if (fieldName === 'userCity') {
          return 'Invalid user city';
        }
        if (fieldName === 'userPostalCode') {
          return 'Invalid user postal code';
        }
        if (fieldName === 'instituteType') {
          return 'Invalid institute type';
        }
        if (fieldName === 'instituteName') {
          return 'Invalid institute name';
        }
        if (fieldName === 'instituteWebsite') {
          return 'Invalid institute website';
        }
        if (fieldName === 'instituteEmail') {
          return 'Invalid institute email';
        }
        if (fieldName === 'institutePhone') {
          return 'Invalid institute phone number';
        }
        if (fieldName === 'instituteAddress') {
          return 'Invalid institute address';
        }
        if (fieldName === 'instituteState') {
          return 'Invalid institute state';
        }
        if (fieldName === 'instituteCity') {
          return 'Invalid institute city';
        }
        if (fieldName === 'institutePostalCode') {
          return 'Invalid institute postal code';
        }
        if (fieldName === 'classes') {
          return 'Invalid classes';
        }

        return 'This field is required';
      case 'minlength':
        if (fieldName == 'password') {
          return 'Password length should be 8 digits';
        }
        return 'Minimum length not fulfilled';
      case 'email':
        return 'Invalid email id';
      case 'mustMatch':
        return 'Entered password and confirm password is different';
      default:
        return 'Unhandled error for - ' + errorName;
    }
  }
}
