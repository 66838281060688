import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-footer',
  standalone: false,
  // imports: [],
  templateUrl: './main-footer.component.html',
  styleUrl: './main-footer.component.scss',
})
export class MainFooterComponent {
  _router = inject(Router);

  openWhatsApp() {
    const msg = 'Hi, I would like to know more about your services.\n'
    const currentUrl = `Source: https://www.shikshatam.com${this._router.url}`;

    const encodedText = encodeURIComponent(msg + currentUrl)

    window.open(
      `https://api.whatsapp.com/send/?phone=%2B919179328236&text=${encodedText}`,
      '_blank'
    );
  }

  navigateToPrivacy() {
    this._router.navigateByUrl("/privacy")
  }

  navigateToTerms() {
    this._router.navigateByUrl("/terms")
  }
}
