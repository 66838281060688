<app-main-header></app-main-header>

<main>
  <div class="container" *ngIf="instituteDetail">
    <div class="details">
      <div class="hero">
        <div class="gallery">
          <ngb-carousel
            #carousel
            [interval]="250000"
            [pauseOnHover]="true"
            [pauseOnFocus]="true"
          >
            @for (img of instituteDetail.institute?.gallery; track img; let i =
            $index) {
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img
                  [src]="img.path"
                  alt="{{ instituteDetail.institute.name }} image {{ i + 1 }}"
                />
              </div>
            </ng-template>
            }
          </ngb-carousel>
        </div>
        <div class="institute">
          <div class="institute__media">
            <!-- ../../../assets/icons/university.png -->
            <img
              class="w-100"
              [src]="instituteDetail.institute.logo"
              alt="{{instituteDetail.institute.name}} logo"
            />
          </div>
          <div class="institute__info">
            <div class="institute__info--name">
              {{ instituteDetail.institute.name | titlecase }}
            </div>
            <div
              *ngIf="instituteDetail.teachersData.length"
              class="institute__info--teachers"
            >
              {{ instituteDetail.teachersData.length }} Teachers
            </div>
            <div class="institute__info--experience">
              {{ instituteDetail.experienceText }}
            </div>
            <div class="institute__info--address">
              {{ instituteDetail.institute.address | titlecase }},
              {{ instituteDetail.institute.state.name }},
              {{ instituteDetail.institute.city.name }}
            </div>
          </div>
          <div class="institute__action">
            <button
              class="btn btn-primary"
              (click)="openContactModal(instituteDetail.institute._id)"
            >
              Contact
            </button>
          </div>
        </div>
      </div>
      <div class="header">
        <div
          class="header__item c-pointer"
          [ngClass]="currentTab === 'OVERVIEW' ? 'active' : ''"
          (click)="toggleTabs('OVERVIEW')"
        >
          Overview
        </div>
        <div
          class="header__item c-pointer"
          [ngClass]="currentTab === 'TEACHERS' ? 'active' : ''"
          (click)="toggleTabs('TEACHERS')"
        >
          Teachers ({{ instituteDetail.teachersData.length }})
        </div>
        <div
          class="header__item c-pointer"
          [ngClass]="currentTab === 'BATCHES' ? 'active' : ''"
          (click)="toggleTabs('BATCHES')"
        >
          Batches ({{ instituteDetail.batches.length }})
        </div>
      </div>

      <!-- <div class="container mt-4"> -->
      <div class="overview" *ngIf="currentTab === 'OVERVIEW'">
        <div class="container mt-4">
          <!-- Title Section -->
          <div class="overview__title mb-4 text-center">
            <h1 class="display-6 text-dark">
              {{ instituteDetail.institute.name | titlecase }}
            </h1>
          </div>

          <div class="overview__wrapper row g-4">
            <!-- Description Section -->
            <div class="col-md-8">
              <p
                class="text-muted fs-5 text-break lh-sm"
                [innerHTML]="instituteDetail.institute.briefInfo"
              ></p>

              <!-- Horizontal Divider -->
              <hr class="my-4" />

              <!-- Courses Offered Section -->
              <section class="mb-4">
                <h4 class="text-dark mb-3">Type</h4>
                <p class="text-muted fs-5">
                  @for (type of instituteDetail.institute.type; track type; let
                  i = $index) {
                  {{ type.type }}
                  {{
                    i !== instituteDetail.institute.type.length - 1 ? "|" : ""
                  }}
                  }
                </p>
              </section>

              <!-- Class Levels Section -->
              <section>
                <h4 class="text-dark mb-3">Class</h4>
                <p class="text-muted fs-5">
                  @for (class of instituteDetail.institute.classes; track class;
                  let i = $index) {
                  {{ class.name }}
                  {{
                    i !== instituteDetail.institute.classes.length - 1
                      ? "|"
                      : ""
                  }}
                  }
                </p>
              </section>
            </div>

            <!-- Map Section -->
            <div class="col-md-4">
              <section class="text-center">
                <div
                  id="map_canvas"
                  class="ratio ratio-16x9 mb-4 rounded-3 border border-light overflow-hidden shadow-sm"
                >
                  <!-- <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24921.87521712962!2d75.90496573211051!3d22.762820765844545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39631d5c8cb6d35b%3A0x87f82536b592f7cb!2sMahalaxmi%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452010!5e0!3m2!1sen!2sin!4v1723631570045!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe> -->
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div
        class="teachers"
        *ngIf="currentTab === 'OVERVIEW' || currentTab === 'TEACHERS'"
      >
        <!-- <div class="teachers__title text-center display-6 text-dark ">
          Teachers in {{ instituteDetail.institute.name | titlecase }}
        </div> -->

        <div class="overview__title mb-4 text-center">
          <h2 class="display-6 text-dark">
            Teachers in {{ instituteDetail.institute.name | titlecase }}
          </h2>
        </div>
        <div
          class="teachers__item"
          *ngFor="let teacher of instituteDetail.teachersData"
        >
          <app-teacher-card [teacher]="teacher"></app-teacher-card>
        </div>
      </div>

      <div
        class="batches"
        *ngIf="currentTab === 'OVERVIEW' || currentTab === 'BATCHES'"
      >
        <div class="batches__title">
          Batches in {{ instituteDetail.institute.name | titlecase }}
        </div>
        <div
          class="batches__item"
          *ngFor="let batch of instituteDetail.batches"
        >
          <app-batch-card [batch]="batch"></app-batch-card>
        </div>
        <div class="">
          <iframe
            style="width: -webkit-fill-available"
            *ngIf="instituteDetail.institute.ytURL"
            height="315"
            [src]="instituteDetail.institute.ytURL | safe : 'resourceUrl'"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <!-- Related categories section -->
      <div class="related-categories-section mb-4" *ngIf="relatedCategories.length > 0">
        <h5 class="mb-1">
          Related Categories
        </h5>
        <div class="related-links" >
          @for (obj of relatedCategories; track obj; let  i = $index) {  
            <span>
              <a 
                [routerLink]="obj.link" 
                [queryParams]="obj.queryParams"
              > 
                {{obj.text | titlecase }} 
              </a> 
              {{i != relatedCategories.length-1 ? '|': ''}}
            </span>
          }
            
        </div>
      </div>

       <!-- Related institutes section -->
       <div class="related-institutes-section">
        <h5 class="mb-1">
          Related Institutes
        </h5>
        @for (instituteTypeObj of instituteDetail.institute.type; track instituteTypeObj.type; let typeIndex = $index) {  
          <h6 class="fs-6 mt-3 mb-0" *ngIf="relatedInstitutes[typeIndex] && relatedInstitutes[typeIndex].length > 0">
            Top 10 {{instituteTypeObj.type | titlecase }} in {{instituteDetail.institute.city.name | titlecase }}
          </h6>
          <div class="related-links" *ngIf="relatedInstitutes[typeIndex] && relatedInstitutes[typeIndex].length > 0">
            @for (obj of relatedInstitutes[typeIndex]; track obj.text; let  instituteIndex = $index) {  
              <span>
                <a 
                  [routerLink]="obj.link" 
                  [queryParams]="obj.queryParams"
                > 
                  {{obj.text | titlecase }}
                </a> 
                {{instituteIndex != relatedInstitutes[typeIndex].length-1 ? '|': ''}}
              </span>
            }
              
          </div>
        }
      </div>
    </div>
  </div>
</main>
<app-main-footer></app-main-footer>
