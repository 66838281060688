import { AfterViewInit, Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter } from 'rxjs';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'aggregator';
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private meta: Meta
  ) {}

  ngAfterViewInit() {
    this.router.events
      // .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          console.log('NavigationStart:', event.url);
        } else if (event instanceof NavigationEnd) {
          console.log('NavigationEnd:', event.urlAfterRedirects);
          gtag('config', 'G-JSMP4JQTYE', {
            page_path: event.urlAfterRedirects,
          });

          const fragment = this._route.snapshot.fragment;
          if (fragment) {
            this.scrollToFragment(fragment);
          }
          const canonicalUrl = `https://www.shikshatam.com${this.router.url}`;

          const existingCanonical = document.querySelector(
            'link[rel="canonical"]'
          );
          if (existingCanonical) {
            document.head.removeChild(existingCanonical);
          }

          const link = document.createElement('link');
          link.rel = 'canonical';
          link.href = canonicalUrl;
          document.head.appendChild(link);

          this.meta.updateTag({ name: 'canonical', content: canonicalUrl });
        }
      });
  }

  scrollToFragment(fragment: string) {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToElement(section: any) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
