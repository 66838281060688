<div class="modal-header">
  <h4 class="modal-title">Contact Institute</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<form [formGroup]="leadForm">
  <div class="modal-body">
    <p>Please fill in the below details to send your enquiry.</p>
    <div class="mb-3">
      <input
        formControlName="name"
        type="text"
        class="form-control"
        placeholder="Full Name"
      />
    </div>
    <div class="mb-3">
      <input
        formControlName="phone"
        type="text"
        class="form-control"
        placeholder="Mobile"
      />
    </div>
    <div class="mb-3">
      <textarea
        cols="30"
        rows="5"
        class="form-control"
        placeholder="Message"
        formControlName="message"
      ></textarea>
    </div>
  </div>
</form>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onSubmitRequest()">
    Submit Request
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="close()">
    Close
  </button>
</div>
