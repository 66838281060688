<div class="filter">
  <!-- <div
    [ngStyle]="{ width: !hideTypeBar ? '25%' : '100%' }"
    class="filter__city"
  > -->
  <div
    [ngClass]="hideTypeBar ? 'filter__city filter__city--full' : 'filter__city'"
  >
    <ng-select
      placeholder="Select City"
      [items]="cities"
      bindLabel="name"
      bindValue="_id"
      [(ngModel)]="selectedCity"
      (ngModelChange)="onSelectCity($event)"
    >
    </ng-select>
  </div>
  <div *ngIf="!hideTypeBar" class="filter__type">
    <ng-select
      placeholder="Select Institute Type"
      [items]="types"
      bindLabel="type"
      bindValue="_id"
      [(ngModel)]="selectedType"
      (ngModelChange)="onSelectType($event)"
    >
    </ng-select>
  </div>
</div>
