<footer id="footer" class="footer">
  <div class="container">
    <!-- <div class="row">
        <div
          class="col-md-3 col-sm-6 col-xs-12 wow fadeInUp animated"
          data-wow-duration="500ms"
        >
          <div class="footer-single">
            <img src="img/logo.png" alt="" />
            <p>
              Shikshatam is a pioneering company dedicated to assisting
              academic institutions in navigating the complexities of
              management and administration. Our comprehensive suite of
              services includes IT solutions, student information systems,
              digital marketing strategies, and customized software
              development aimed at streamlining operations, enhancing student
              engagement, and maximizing institutional growth. With a focus on
              innovation and excellence, Shikshatam empowers educators and
              administrators to focus on what they do best - shaping the minds
              of the future.
            </p>
          </div>
        </div>

        <div
          class="col-md-3 col-sm-6 col-xs-12 wow fadeInUp animated"
          data-wow-duration="500ms"
          data-wow-delay="300ms"
        >
          <div class="footer-single">
            <h6>Subscribe</h6>
            <form action="#" class="subscribe">
              <input type="text" name="subscribe" id="subscribe" />
              <input type="submit" value="&#8594;" id="subs" />
            </form>
            <p>eusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
        </div>

        <div
          class="col-md-3 col-sm-6 col-xs-12 wow fadeInUp animated"
          data-wow-duration="500ms"
          data-wow-delay="600ms"
        >
          <div class="footer-single">
            <h6>Explore</h6>
            <ul>
              <li><a href="#">Inside Us</a></li>
              <li><a href="#">Flickr</a></li>
              <li><a href="#">Google</a></li>
              <li><a href="#">Forum</a></li>
            </ul>
          </div>
        </div>

        <div
          class="col-md-3 col-sm-6 col-xs-12 wow fadeInUp animated"
          data-wow-duration="500ms"
          data-wow-delay="900ms"
        >
          <div class="footer-single">
            <h6>Support</h6>
            <ul>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Market Blog</a></li>
              <li><a href="#">Help Center</a></li>
              <li><a href="#">Pressroom</a></li>
            </ul>
          </div>
        </div>
      </div> -->

    <div class="row">
      <div class="col-md-12">
        <p class="social text-center">
          <a
            target="_blank"
            href="https://www.linkedin.com/company/shikshatam-it-private-limited"
            ><i class="fa fa-linkedin"></i
          ></a>
          <a
            target="_blank"
            href="https://www.facebook.com/profile.php?id=61561980560946"
            ><i class="fa fa-facebook"></i
          ></a>
          <a
            target="_blank"
            href="https://www.instagram.com/shikshatam/?igsh=MXBvenNhN2tpOHNlYg%3D%3D"
            ><i class="fa fa-instagram"></i
          ></a>
          <a target="_blank" href="https://twitter.com/Shikshatam"
            ><i class="fa fa-twitter"></i
          ></a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCYUQ0oigexB51K6ZiY4GhWw"
            ><i class="fa fa-youtube"></i
          ></a>
        </p>
      </div>
    </div>
    <!-- Articles Section -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p class="copyright text-center">
            Copyright © 2024
            <a href="https://www.shikshatam.com/">Shikshatam IT Private Limited</a>
            <br />
            All rights reserved. Designed & developed by
            <a href="https://www.shikshatam.com/">Shikshatam IT Private Limited.</a>
          </p>
          <p class="text-center policies">
            <a
              (click)="navigateToTerms()"
              style="
                text-decoration: underline;
                margin-inline: 12px;
                cursor: pointer;
              "
              >Terms & Conditions</a
            >
            <a
              style="
                text-decoration: underline;
                margin-inline: 12px;
                cursor: pointer;
              "
              (click)="navigateToPrivacy()"
              >Privacy
            </a>
          </p>
          <!-- <p class="text-center">
            <a
              style="text-decoration: underline"
              style="margin-left: 12px"
              href="https://www.shikshatam.com/privacy"
              >Privacy
            </a>
          </p> -->
        </div>
      </div>
    </div>

    <div class="fab" (click)="openWhatsApp()">
      <img src="../../../assets/icons/whatsapp.png" alt="WhatsApp" />
    </div>
  </div>
</footer>
