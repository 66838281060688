<div class="container checkout-container">
  <div class="row">
    <div class="col-md-8">
      <!-- Contact Information -->
      <!-- <div class="form-section">
          <h4>Contact</h4>
          <div class="form-group">
            <label for="contactEmail">Email or mobile phone number</label>
            <input
              type="email"
              class="form-control"
              id="contactEmail"
              placeholder="Email or mobile phone number"
            />
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="newsOffers" />
            <label class="form-check-label" for="newsOffers"
              >Email me with news and offers</label
            >
          </div>
        </div> -->
      <!-- Delivery Information -->
      <div class="form-section">
        <h4>Billing Address</h4>
        <div class="form-group">
          <label for="country">Country/Region</label>
          <select class="form-control" id="country">
            <option>India</option>
            <!-- Add other countries as needed -->
          </select>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="firstName">First name</label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              placeholder="First name"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="lastName">Last name</label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              placeholder="Last name"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="address">Address</label>
          <input
            type="text"
            class="form-control"
            id="address"
            placeholder="Address"
          />
        </div>
        <div class="form-group">
          <label for="address2">Apartment, suite, etc.</label>
          <input
            type="text"
            class="form-control"
            id="address2"
            placeholder="Apartment, suite, etc."
          />
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="city">City</label>
            <input
              type="text"
              class="form-control"
              id="city"
              placeholder="City"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="state">State</label>
            <input
              type="text"
              class="form-control"
              id="state"
              placeholder="State"
            />
          </div>
          <div class="form-group col-md-2">
            <label for="zip">PIN code</label>
            <input
              type="text"
              class="form-control"
              id="zip"
              placeholder="PIN code"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="phone">Phone</label>
          <input
            type="tel"
            class="form-control"
            id="phone"
            placeholder="Phone"
          />
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="saveInfo" />
          <label class="form-check-label" for="saveInfo"
            >Save this information for next time</label
          >
        </div>
      </div>
      <!-- Shipping Method -->
      <!-- <div class="form-section">
          <h4>Shipping method</h4>
          <div class="form-group">
            <label for="shippingMethod"
              >Enter your shipping address to view available shipping
              methods.</label
            >
            <input
              type="text"
              class="form-control"
              id="shippingMethod"
              placeholder="Shipping method"
            />
          </div>
        </div> -->
      <!-- Payment Information -->
      <div class="form-section">
        <h4>Payment</h4>
        <p>All transactions are secure and encrypted.</p>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="paymentMethod"
            id="paymentPhonePe"
            value="phonepe"
            checked
          />
          <label class="form-check-label" for="paymentPhonePe">
            PhonePe Payment Gateway (UPI, Cards & NetBanking)
          </label>
        </div>
        <!-- <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="paymentMethod"
              id="paymentCOD"
              value="cod"
            />
            <label class="form-check-label" for="paymentCOD">
              Cash on Delivery (COD)
            </label>
          </div> -->
      </div>
      <!-- Billing Address -->
      <!-- <div class="form-section">
          <h4>Billing address</h4>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="billingAddress"
              id="billingSame"
              value="same"
              checked
            />
            <label class="form-check-label" for="billingSame">
              Same as shipping address
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="billingAddress"
              id="billingDifferent"
              value="different"
            />
            <label class="form-check-label" for="billingDifferent">
              Use a different billing address
            </label>
          </div>
        </div> -->
      <button type="button" class="btn btn-primary btn-lg btn-block">
        Pay now
      </button>
    </div>
    <!-- Order Summary -->
    <div class="col-md-4">
      <div class="checkout-summary">
        <h4>Order Summary</h4>
        <div class="d-flex justify-content-between">
          <span>Education Management Platform Advanced Version</span>
        </div>
        <hr />
        <!-- <div class="form-group">
            <label for="discountCode">Discount code</label>
            <input
              type="text"
              class="form-control"
              id="discountCode"
              placeholder="Discount code"
            />
            <button type="button" class="btn btn-secondary btn-block mt-2">
              Apply
            </button>
          </div> -->
        <hr />

        <div class="d-flex justify-content-between">
          <span>Total</span>
          <strong id="totalAmount">₹1,200.00</strong>
        </div>
      </div>
    </div>
  </div>
</div>
