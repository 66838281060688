import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  CitiesByInstitute,
  CommonService,
  InstituteTypes,
} from 'src/app/services/common.service';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss'],
})
export class SearchHeaderComponent implements OnInit {
  cities: CitiesByInstitute[] = [];
  types: InstituteTypes[] = [];
  @Input() hideTypeBar: boolean = false;
  @Input() alreadySelectedCityId!: string;
  @Input() alreadySelectedTypeId!: string;
  @Output() selectedCityId$: EventEmitter<string> = new EventEmitter();
  @Output() selectedCityName$: EventEmitter<string> = new EventEmitter();
  @Output() selectedTypeId$: EventEmitter<string> = new EventEmitter();
  @Output() selectedTypeName$: EventEmitter<string> = new EventEmitter();

  _commonService = inject(CommonService);

  selectedCity: any;
  selectedType: any;

  ngOnInit(): void {
    this.fetchCities();
    this.fetchTypes();
  }

  fetchCities() {
    this._commonService.fetchCityByInsitute().subscribe({
      next: (_) => {
        this.cities = _.data;
        this.cities.forEach((_) => {
          if (this.alreadySelectedCityId) {
            this.selectedCity = this.alreadySelectedCityId;
          } else if (_.name === 'Indore') {
            this.selectedCity = _._id;
          }
          this.selectedCityId$.emit(this.selectedCity);
          this.selectedCityName$.emit(
            this.cities.find((_) => _._id === this.alreadySelectedCityId)?.name
          );
        });
      },
      error: (__) => {
        alert(__);
      },
    });
  }

  fetchTypes() {
    if (!this.hideTypeBar) {
      this._commonService.fetchInstituteTypes().subscribe({
        next: (_) => {
          this.types = _.data;
          if (this.alreadySelectedTypeId) {
            this.selectedType = this.alreadySelectedTypeId;
            this.selectedTypeName$.emit(
              this.types.find((_) => _._id === this.alreadySelectedTypeId)?.type
            );
          }
        },
        error: (_) => {
          alert(_);
        },
      });
    }
  }

  onSelectCity(e: string) {
    if (!e) {
      return;
    }
    this.selectedCityId$.emit(e);
    this.selectedCityName$.emit(this.cities.find((_) => _._id === e)?.name);
  }

  onSelectType(e: string) {
    if (!e) {
      return;
    }
    this.selectedTypeId$.emit(e);
    this.selectedTypeName$.emit(this.types.find((_) => _._id === e)?.type);
  }
}