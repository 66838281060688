<app-main-header></app-main-header>
<div class="container mt-5">
  <div class="row">
    <!-- Main Content Section -->
    <div class="col-md-8">
      <div *ngIf="blog">
        <h1 class="display-3 mb-2">{{ blog.title }}</h1>
        <p class="text-muted author-name">By {{ blog.by }}</p>

        <!-- Styling the key -->
        <p class="text-muted font-weight-bold mb-2">
        {{ blog.key }}
        </p>
        
        <img
          [src]="blog.imageUrl"
          class="img-fluid rounded mb-4 shadow-sm mx-auto d-block"
          alt="{{ blog.title }}"
        />
        
        <p class="lead" [innerHTML]="blog.content"></p>
      </div>
    </div>
    

    <!-- Sidebar Section -->
    <div class="col-md-4">
      <!-- Subscription Card -->
      <!-- <div class="card mb-4 shadow-sm">
        <div class="card-body">
          <h5 class="card-title">Subscribe to Shikshatam Blog</h5>
          <p class="card-text">Find the Best Institute Near You</p>
          <form class="d-flex flex-column">
            <div class="mb-3">
              <input type="email" class="form-control" placeholder="abc@xyz.com" />
            </div>
            <button type="submit" class="btn btn-primary w-100">Subscribe Now</button>
          </form>
        </div>
      </div> -->

      <!-- Follow Us Card -->
      <!-- <div class="card mb-4 shadow-sm">
        <div class="card-body text-center">
          <h5 class="card-title">Follow us on FB</h5>
          <button class="btn btn-secondary mb-3">Follow us on FB</button>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">348,036 followers</h5>
              <p class="card-text">Follow Shikshatam</p>
              <a href="#" class="btn btn-primary">Follow Page</a>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Additional Resources Card -->
      <div class="card mb-4 shadow-sm">
        <div class="card-body">
          <h5 class="card-title">Additional Resources</h5>
          <ul class="list-unstyled">
            <li>
              <i class="fa fa-chevron-right me-2"></i> Education Resources
            </li>
            <li><i class="fa fa-chevron-right me-2"></i> Teacher Training</li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Online Learning Platforms
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Best Educational Tools
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Professional Development
              for Teachers
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Innovative Teaching
              Methods
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Top Education Blogs
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Educational Technology
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> School Improvement
              Strategies
            </li>
            <li>
              <i class="fa fa-chevron-right me-2"></i> Teacher Support Networks
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-main-footer></app-main-footer>
