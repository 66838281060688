<div class="container text-center mt-5">
  <span *ngIf="!minimalUI" class="display-4 mb-4">
    Teacher near me
  </span>
  <div
    class="search-bar mt-4 mb-4 d-flex justify-content-center align-items-center"
  >
    <div class="input-group">
      <div class="input-group--select">
        <ng-select
          placeholder="Select Type or Institute"
          bindLabel="name"
          bindValue="_id"
          groupBy="groupName"
          class="form-control search-input institute-type text-start"
          [(ngModel)]="selectedTypeOrInstituteId"
          (ngModelChange)="onSelectTypeOrInstitute($event)"
          (search)="onTypeAndInstituteSearch($event)"
          (clear)="onTypeAndInstituteClear()"
          [items]="filteredTypeAndInstitutes" 
        >
        <ng-template ng-optgroup-tmp let-item="item">
          {{ item.groupName }}
        </ng-template>
        </ng-select>
        <!--     
        <input
          type="text"
          class="form-control search-input"
          placeholder="Select Type"
          [(ngModel)]="searchType"
          (input)="filterTypes()"
          (focus)="showSuggestions = true"
          (blur)="hideSuggestions()"
        /> -->

        <ng-select
          #locationSelect
          placeholder="Location"
          bindLabel="name"
          bindValue="_id"
          class="form-control search-input"
          [(ngModel)]="selectedCityId"
          (ngModelChange)="onSelectCity($event)" 
        >
          <ng-option value="LOCATE_ME">
            <img
              src="../../assets/icons/nearby.png"
              width="20px"
              height="20px"
              alt="Near Me"
            />
            Near Me
          </ng-option>
          <ng-option *ngFor="let city of cities" [value]="city._id">{{
            city.name
          }}</ng-option>
        </ng-select>
      </div>

      <!-- <input
        type="text"
        class="form-control search-input"
        placeholder="Teacher, Institute or Location"
        [(ngModel)]="searchCity"
        (input)="filterLocations()"
        (focus)="showLocationSuggestions = true"
        (blur)="hideLocationSuggestions()"
      /> -->
      <!-- <div class="location-dropdown-menu" *ngIf="showLocationSuggestions">
        <button class="dropdown-item" (click)="onLocateUser()">
          <i [class]="'fa fa-location-arrow'"></i>
          Near Me
        </button>
        <button
          class="dropdown-item"
          *ngFor="let location of filteredCities"
          (click)="onSelectCity(location)"
        >
          {{ location.name }}
        </button>
      </div> -->
      <button class="btn btn-primary search-btn" (click)="onSearch()">
        Search
      </button>
    </div>
  </div>

  <div
    *ngIf="!minimalUI"
    class="categories mt-4 d-flex justify-content-center align-items-center"
  >
    <button
      class="btn btn-outline-secondary btn-circle"
      (click)="scrollCategories('left')"
      id="scrollLeft"
    >
      &larr;
    </button>
    <div class="category-container d-inline-block mx-3" #categoryContainer>
      <ng-container *ngFor="let type of instituteTypes">
        <span class="category-item" (click)="onSelectInstituteType(type._id)">
          <!-- <i [class]="type.icon"></i> -->

          {{ type.type }}
        </span>
      </ng-container>
    </div>
    <button
      class="btn btn-outline-secondary btn-circle"
      (click)="scrollCategories('right')"
      id="scrollRight"
    >
      &rarr;
    </button>
  </div>
</div>
