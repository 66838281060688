<nav class="navbar navbar-expand-lg bg-primary">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/" fragment="top">
      <img
        style="width: 90px"
        src="../../../assets/images/logo.png"
        alt="Shikshatam"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleCollapsed()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      [ngClass]="{ collapse: collapsed, 'navbar-collapse ': true }"
    >
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" routerLink="/" fragment="top">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/" fragment="features">Why Us</a>
          <!-- <a class="nav-link" (click)="scrollToElement('features')">Why Us</a> -->
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            target="_blank"
            onclick="window.open('https://portal.shikshatam.com/login')"
            >Admin Portal</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            target="_blank"
            onclick="window.open('https://www.shikshatam.com/report-issue')"
            >Report Issue/Complain</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/" fragment="contact">Contact Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/blogs-listing">Blogs</a>
        </li>
      </ul>
      <div class="desktop-menu-action">
        <button
          class="btn btn-sm btn-light mx-2"
          routerLink="/"
          fragment="join_us"
        >
          Enquiry
        </button>
        <button
          class="btn btn-sm btn-primary register-btn"
          [routerLink]="'/register'"
        >
          Register
        </button>
      </div>
    </div>
  </div>
</nav>
