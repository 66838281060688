import { Institute } from './../services/detail.service';
import { AfterViewInit, Component, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactFormComponent } from '../shared/contact-form/contact-form.component';
import { ActivatedRoute } from '@angular/router';
import { DetailService, InstituteByUrlCode } from '../services/detail.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {ListingFetchResponse, InstituteListings} from '../services/listing.service'
import { aggregateInstitutes } from '../utils/api.constant';
import { normalizeTypeName } from '../utils/string'
import { MetaService } from './../utils/meta';
import {  Meta } from '@angular/platform-browser';

declare var google: any;

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  providers: [DetailService],
})
export class DetailComponent implements AfterViewInit {
  private _route = inject(ActivatedRoute);
  private _modalService = inject(NgbModal);
  private _service = inject(DetailService);
  _http = inject(HttpClient);



  currentTab = 'OVERVIEW';
  urlCode!: string;
  instituteDetail!: InstituteByUrlCode;
  relatedCategories: Array<any> = []
  relatedInstitutes: Array<Array<any>> = []

  constructor( 
  private metaService: MetaService,
  private meta:Meta
  ) {}

  ngAfterViewInit(): void {
    this._route.params.subscribe({
      next: (param: any) => {
        if (param.hasOwnProperty('urlCode')) {
          this.urlCode = param.urlCode;
          this.fetchDetail();
        }
      },
    });
  }

  fetchDetail() {
    this.instituteDetail = {} as InstituteByUrlCode;
    this._service.fetchDetails(this.urlCode).subscribe({
      next: (_) => {
        this.instituteDetail = _.data;
        if (_.data.institute.location?.coordinates[0] !== 0) {
          setTimeout(() => {
            this.intializeMap(
              _.data.institute.location?.coordinates[1] as number,
              _.data.institute.location?.coordinates[0] as number
            );
          }, 1000);
        }

        this.generateRelatedLinks()
        this.setMetaInfo();
      },
      error: (_) => {
        alert(_);
      },
    });
  }

  openContactModal(instituteId: string) {
    const modalRef = this._modalService.open(ContactFormComponent);
    modalRef.componentInstance.instituteId = instituteId;
  }

  toggleTabs(tab: string) {
    this.currentTab = tab;
  }

  intializeMap(lat: number, lng: number) {
    console.log('LT LN : ', lat, lng);

    var myLatLng = new google.maps.LatLng(lat, lng);
    var mapOptions = {
      zoom: 14,
      center: myLatLng,
      disableDefaultUI: true,
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: false,
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'roadatlas'],
      },
    };
    var map = new google.maps.Map(
      document.getElementById('map_canvas'),
      mapOptions
    );
    var marker = new google.maps.Marker({
      position: myLatLng,
      map: map,
      // icon: "assets/images/location-icon.png",
      title: '',
    });

    google.maps.event.addListener(map, 'click', function () {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
        '_blank'
      );
    });
  }

  generateRelatedLinks(){
    const instituteTypes = this.instituteDetail.institute.type
    const instituteCity = this.instituteDetail.institute.city

    // Empty the old list before creating new one.
    this.relatedCategories = []
    this.relatedInstitutes = []

    const normalizedCityName = instituteCity.name.toLowerCase()

    instituteTypes.forEach(typeObj => {
      const normalizedTypeName = normalizeTypeName(typeObj.type)

      // Generate listing page links based on the institute's types and city
      this.relatedCategories.push({
        text: `${typeObj.type} in ${instituteCity.name}`,
        link: `/listing/${normalizedCityName}/${normalizedTypeName}`,
        queryParams: {
          city: instituteCity._id,
          type: typeObj._id,
          searchMode: 'BY_CITY'
        }
      })

      // Generate links of top 10 institutes that fall under the same category/type as the current institute
      this.fetchInstitutesByTypeAndCity(typeObj._id, instituteCity._id)
      .subscribe({
        next: (_: any) => {
          const response = _.data
          const slicedData = response.slice(0,10)
          const finalData: Array<Object> = []
          slicedData.forEach((instituteObj: InstituteListings) => {
            finalData.push({
              text: instituteObj.instituteName,
              link: `/institute/${instituteObj.urlCode}`,
              queryParams: {}
            })
          })

          this.relatedInstitutes.push(finalData)

          
        },
        error: (__: any) => {
          // Empty the institutes list if something goes wrong
          this.relatedInstitutes = []
        },
      });

    })
  }

  fetchInstitutesByTypeAndCity(typeId: string, cityId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('city', cityId);
    httpParams = httpParams.append('type', typeId);

    return this._http.get<ListingFetchResponse>(aggregateInstitutes, {
      params: httpParams,
    }) 
  }

  setMetaInfo() {
    // Set dynamic meta tags based on the fetched data
    const instituteDescription = this.instituteDetail.institute.briefInfo
      ?.replace(/(<([^>]+)>)/gi, '')
      .replace(/"/g, '')
      .replace(/[^\x20-\x7E]/g, ' ')
      .replace(/\&nbsp;/g, ' ');
     
    const instituteType: string[]=[]
    this.instituteDetail.institute.type.forEach((item, index) => {
      instituteType.push(item.type);
    });   
    const title = this.instituteDetail.institute.name + ' ' + this.instituteDetail.institute.address;
    const description = instituteDescription|| title;
    const keyword = `${title}, ${instituteType.join(", ")}, ${this.instituteDetail.institute.city.name}`;
    this.metaService.setMeta(title, description, keyword )

    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({
     property: 'og:description',
     content: `Best ${instituteType.join(", ")} in ${this.instituteDetail.institute.city.name}`,
  })
}
}
