import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListingComponent } from './listing/listing.component';
import { DetailComponent } from './detail/detail.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { TermsComponent } from './shared/terms/terms.component';
import { PrivacyComponent } from './shared/privacy/privacy.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogsListingComponent } from './blogs-listing/blogs-listing.component';
import { ArticleComponent } from './article/article.component';

const routes: Routes = [
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'listing',
    component: ListingComponent,
  },
 
  { path: 'listing/:city/:type', component: ListingComponent },

  {
    path: 'checkout',
    component: CheckoutComponent,
  },
  {
    path: 'institute/:urlCode',
    component: DetailComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'blogs',
    component: BlogsComponent,
  },
  {
    path: 'blogs-listing',
    component: BlogsListingComponent,
  },
  { path: 'blog/:id', component: BlogsComponent },
  { path: 'article/:weburl', component: ArticleComponent },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
