import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import CommonModule for ngFor and ngIf
import { Router } from '@angular/router'; // Import Router for navigation
import { BlogService } from '../services/blogs.service';
import { SharedModule } from '../shared/shared.module'; // Adjust the path as necessary

@Component({
  selector: 'app-blogs-listing',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './blogs-listing.component.html',
  styleUrls: ['./blogs-listing.component.scss'],
})
export class BlogsListingComponent implements OnInit {
  blogs: any[] = [];

  constructor(private blogService: BlogService, private router: Router) {}

  ngOnInit(): void {
    this.blogService.getBlogs().subscribe((data) => {
      this.blogs = data;
    });
  }

  // Method to handle redirection
  redirectToBlog(weburl: string): void {
    this.router.navigate(['/blog', weburl]);
  }
}
