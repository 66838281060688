<app-main-header></app-main-header>

<div class="hero">
  <h2>Let's Setup Your Account</h2>
  <p>Please fill in the below details to setup your Shikshatam Account</p>
</div>
<div class="container py-3 form-container">
  <div class="card">
    <!-- <h3 class="card-header">
      Create User
      <label class="switch float-end">
        <input
          [formControl]="createUserAlsoFC"
          (change)="onChangeCreateUserSwitch($event)"
          type="checkbox"
          id="toggleButton"
        />
        <span class="slider round"></span>
      </label>
    </h3> -->
    <form id="registerForm" [formGroup]="instituteForm">
      <div class="card-body" *ngIf="createUserAlsoFC.value">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="firstName" class="form-label"
                >First Name <span style="color: red">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="firstName"
                name="firstName"
                formControlName="firstName"
                placeholder="Enter first name"
                required
              />
              <field-errors
                [fieldName]="'firstName'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="lastName" class="form-label"
                >Last Name <span style="color: red">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="lastName"
                formControlName="lastName"
                name="lastName"
                placeholder="Enter last name"
                required
              />
              <field-errors
                [fieldName]="'lastName'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="userAddress" class="form-label"
            >Address <span style="color: red">*</span></label
          >
          <input
            type="text"
            name="adminAddress"
            class="form-control"
            formControlName="adminAddress"
            id="userAddress"
            placeholder="Enter user address"
            required
          />
          <field-errors
            [fieldName]="'adminAddress'"
            [formGroup]="instituteForm"
            [getErrorMessage]="getErrorMessage.bind(this)"
          ></field-errors>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="mb-3">
              <label for="userState" class="form-label"
                >State <span style="color: red">*</span></label
              >
              <!-- <input
                autocomplete="off"
                type="text"
                class="form-control"
                id="userState"
                formControlName="userState"
                name="adminState"
                placeholder="Enter user state"
                required
              /> -->
              <select
                autocomplete="new-password"
                name="type"
                class="form-control"
                formControlName="adminState"
                id="userState"
                placeholder="Enter user state"
                required
                (change)="onStateChange($event)"
              >
                <option value="" disabled selected>Select State</option>

                <option *ngFor="let item of states" [value]="item?._id">
                  {{ item?.name | titlecase }}
                </option>
              </select>
              <field-errors
                [fieldName]="'adminState'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="userCity" class="form-label"
                >User City <span style="color: red">*</span></label
              >
              <!-- <input
                type="text"
                autocomplete="off"
                formControlName="userCity"
                class="form-control"
                id="userCity"
                name="adminCity"
                placeholder="Enter user city"
                required
              /> -->
              <select
                name="type"
                class="form-control"
                formControlName="adminCity"
                id="instituteType"
                placeholder="Enter user city"
                required
              >
                <option value="" disabled selected>Select City</option>

                <option *ngFor="let item of cities" [value]="item?._id">
                  {{ item?.name | titlecase }}
                </option>
              </select>
              <field-errors
                [fieldName]="'adminCity'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="userPostalCode" class="form-label"
                >User Postal Code <span style="color: red">*</span></label
              >
              <input
                type="number"
                class="form-control"
                id="userPostalCode"
                formControlName="adminPostalCode"
                minlength="6"
                maxlength="6"
                name="adminPostalCode"
                placeholder="Enter user postal code"
                required
              />
              <field-errors
                [fieldName]="'adminPostalCode'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="mb-3">
              <label for="userEmail" class="form-label">Email</label>
              <input
                type="email"
                class="form-control"
                id="userEmail"
                formControlName="adminEmail"
                name="adminEmail"
                placeholder="Enter user email"
              />
              <field-errors
                [fieldName]="'adminEmail'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="userPhone" class="form-label"
                >User Phone <span style="color: red">*</span></label
              >
              <input
                type="tel"
                class="form-control"
                id="userPhone"
                formControlName="adminPhone"
                name="adminPhone"
                placeholder="Enter user phone"
                required
              />
              <field-errors
                [fieldName]="'adminPhone'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="dob" class="form-label">Date of Birth</label>
              <input
                type="date"
                name="dob"
                formControlName="dob"
                class="form-control"
                id="dob"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="mb-3">
              <label for="userQualification" class="form-label"
                >Qualification</label
              >
              <input
                type="text"
                class="form-control"
                id="userQualification"
                formControlName="qualification"
                name="qualification"
                placeholder="Enter qualification"
              />
              <field-errors
                [fieldName]="'qualification'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="userSkills" class="form-label">Skills</label>
              <input
                type="text"
                class="form-control"
                id="userSkills"
                formControlName="skills"
                name="skills"
                placeholder="Enter skills"
              />
              <field-errors
                [fieldName]="'skills'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="experience" class="form-label"
                >Experience In Year</label
              >
              <input
                type="number"
                name="experience"
                formControlName="experience"
                class="form-control"
                id="experience"
                placeholder="Enter Experience"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Institute Details -->
      <h3 class="card-header">Institute Details</h3>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3">
              <label for="instituteType" class="form-label"
                >Institute Type <span style="color: red">*</span></label
              >
              <select
                name="type"
                class="form-control"
                formControlName="type"
                id="instituteType"
                required
                multiple
              >
                <option value="" disabled selected>Select Type</option>

                <option *ngFor="let item of instituteTypes" [value]="item?._id">
                  {{ item?.type }}
                </option>
              </select>
              <field-errors
                [fieldName]="'type'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="instituteName" class="form-label"
                >Institute Name <span style="color: red">*</span></label
              >
              <input
                name="name"
                type="text"
                class="form-control"
                maxlength="30"
                formControlName="name"
                id="instituteName"
                placeholder="Enter institute name"
                required
              />
              <field-errors
                [fieldName]="'name'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label for="instituteWebsite" class="form-label"
                >Institute Website</label
              >
              <input
                type="text"
                name="website"
                class="form-control"
                formControlName="website"
                id="instituteWebsite"
                placeholder="Enter institute website"
              />
            </div>
          </div>
        </div>

        <div class="form-check mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            id="isInstituteAddressSame"
            (change)="onSameAddressChange($event)"
            formControlName="isInstituteAddressSame"
          />
          <label class="form-check-label" for="isInstituteAddressSame"
            >Institute details is same as above ?</label
          >
        </div>

        <hr class="my-4" />

        <div class="mb-3">
          <label for="instituteAddress" class="form-label"
            >Institute Address <span style="color: red">*</span></label
          >
          <input
            type="text"
            name="address"
            class="form-control"
            formControlName="address"
            id="instituteAddress"
            placeholder="Enter institute address"
            required
          />
          <field-errors
            [fieldName]="'address'"
            [formGroup]="instituteForm"
            [getErrorMessage]="getErrorMessage.bind(this)"
          ></field-errors>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="instituteEmail" class="form-label"
                >Institute Email</label
              >
              <input
                type="email"
                name="email"
                class="form-control"
                id="instituteEmail"
                formControlName="email"
                placeholder="Enter institute email"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="institutePhone" class="form-label"
                >Institute Phone <span style="color: red">*</span></label
              >
              <input
                type="tel"
                name="phone"
                class="form-control"
                formControlName="phone"
                id="institutePhone"
                placeholder="Enter institute phone"
                required
              />
              <field-errors
                [fieldName]="'phone'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="mb-3 state-autocomplete-container">
              <label for="instituteState" class="form-label"
                >State <span style="color: red">*</span></label
              >
              <!-- <input
                type="text"
                class="form-control"
                id="instituteState"
                formControlName="instituteState"
                name="state"
                required
                autocomplete="off"
              /> -->
              <select
                name="type"
                class="form-control"
                formControlName="state"
                id="instituteType"
                placeholder="Enter institute state"
                (change)="onStateChange($event)"
                required
              >
                <option value="" disabled selected>Select State</option>

                <option *ngFor="let item of states" [value]="item?._id">
                  {{ item?.name | titlecase }}
                </option>
              </select>
              <field-errors
                [fieldName]="'state'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="instituteCity" class="form-label"
                >City <span style="color: red">*</span></label
              >
              <!-- <input
                type="text"
                class="form-control"
                id="instituteCity"
                name="city"
                formControlName="instituteCity"
                
                required
                autocomplete="off"
              /> -->
              <select
                name="type"
                (change)="onCityChange($event)"
                class="form-control"
                formControlName="city"
                id="instituteType"
                placeholder="Enter institute city"
                required
              >
                <option value="" disabled selected>Select City</option>

                <option *ngFor="let item of cities" [value]="item?._id">
                  {{ item?.name | titlecase }}
                </option>
              </select>
              <field-errors
                [fieldName]="'city'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3">
              <label for="userLocality" class="form-label"
                >Locality<span style="color: red">*</span></label
              >
              <ng-select
                [items]="localities"
                bindLabel="name"
                bindValue="_id"
                [formControlName]="'locality'"
                [placeholder]="'Select locality'"
                (change)="onLocalityChange($event)"
                (search)="onSearchLocalities($event)"
                [loading]="isLoadingLocalities"
              >
              </ng-select>
              <field-errors
                [fieldName]="'locality'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="col-md-12">
            <div class="mb-3">
              <label for="institutePostalCode" class="form-label"
                >Postal Code <span style="color: red">*</span></label
              >
              <input
                type="number"
                class="form-control"
                formControlName="postalCode"
                name="postalCode"
                minlength="6"
                maxlength="6"
                id="institutePostalCode"
                placeholder="Enter institute postal code"
                required
              />
              <field-errors
                [fieldName]="'postalCode'"
                [formGroup]="instituteForm"
                [getErrorMessage]="getErrorMessage.bind(this)"
              ></field-errors>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="classes" class="form-label"
            >Choose Classes <span style="color: red">*</span></label
          >
          <!-- <select
            name="classes"
            multiple
            formControlName="classes"
            class="form-control"
            id="classes"
          ></select> -->
          <select
            name="type"
            class="form-control"
            formControlName="classes"
            id="instituteType"
            placeholder="Select class"
            multiple
            required
          >
            <option value="" disabled selected>Select</option>

            <option *ngFor="let item of classes" [value]="item?._id">
              {{ item?.name | titlecase }}
            </option>
          </select>
          <field-errors
            [fieldName]="'classes'"
            [formGroup]="instituteForm"
            [getErrorMessage]="getErrorMessage.bind(this)"
          ></field-errors>
        </div>

        <div class="mb-3">
          <label class="form-label"
            >Choose Logo <span style="color: red">*</span>
          </label>
          <button class="d-flex align-items-center btn">
            <input
              type="file"
              accept="image/*"
              class="form-control"
              (change)="getFileDetail($event)"
            />
          </button>
          <div class="upload-box" *ngIf="croppedImage">
            <div class="preview" id="logoPreview" (click)="openCropModal()">
              <span class="plus-icon">+</span>
              <img [src]="croppedImage" alt="Selected Logo" />
            </div>
            <button
              type="button"
              class="remove-btn"
              (click)="removeLogo(); $event.stopPropagation()"
            >
              X
            </button>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label"
            >Select Gallery <span style="color: red">*</span></label
          >

          <div class="note">
            <p>
              Note: The below images will be used to showcase your institute on
              the Shikshatam platform when students search for it. Please ensure
              that they are of high quality and properly represent your
              institute.
            </p>
          </div>
          <div class="image-upload-container">
            <div
              class="mb-3"
              *ngFor="let index of [0, 1, 2, 3, 4]; let i = index"
            >
              <label class="form-label">Image {{ i + 1 }}</label>
              <button class="d-flex align-items-center btn">
                <input
                  type="file"
                  accept="image/*"
                  class="form-control"
                  (change)="getFileDetailwithIndex($event, i)"
                />
              </button>
              <div class="upload-box" *ngIf="croppedImageWithIndexWorking[i]">
                <div class="preview" id="logoPreview">
                  <span class="plus-icon">+</span>
                  <img
                    [src]="croppedImageWithIndexWorking[i]"
                    alt="Showcased Image {{ i + 1 }}"
                  />
                </div>
                <button
                  type="button"
                  class="remove-btn"
                  (click)="removeLogowithIndex(i); $event.stopPropagation()"
                >
                  X
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label class="form-label">Select Location </label>
            <div style="position: relative; width: 100%; height: 400px">
              <input
                id="searchInput"
                type="text"
                placeholder="Search places..."
                style="
                  position: absolute;
                  top: 10px;
                  left: 10px;
                  right: 10px;
                  z-index: 1;
                  padding: 10px;
                  border-radius: 5px;
                  border: 1px solid #ccc;
                "
              />
              <div id="map" style="height: 100%; width: 100%"></div>
              <button
                (click)="locateMe()"
                style="
                  position: absolute;
                  bottom: 10px;
                  left: 10px;
                  z-index: 1;
                  padding: 10px;
                  border-radius: 5px;
                  background-color: #007bff;
                  color: white;
                  border: none;
                "
              >
                Locate Me
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="mb-3">
              <label for="salesRepCode" class="form-label"
                >Refferal Code (Optional)</label
              >
              <input
                type="text"
                formControlName="salesRepCode"
                name="salesRepCode"
                class="form-control"
                id="salesRepCode"
                placeholder="Enter refferal code"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-md-3">
              <button
                type="button"
                id="registerSubmitBtn"
                class="btn btn-primary"
                style="min-width: 160px"
              >
                Submit
              </button>
            </div> -->
          <div>
            <button
              type="button"
              (click)="onOpenSubscriptionModal()"
              id="paymentBtn"
              class="btn btn-primary"
              style="min-width: 160px"
            >
              Next
            </button>
            <!-- <button
              type="button"
              (click)="openModal(subscriptionContent, 'lg')"
              class="btn btn-outline-dark"
              style="min-width: 160px"
            >
              T::PAY
            </button> -->
            <!-- <button
              type="button"
              (click)="openModal(cropContent, 'md')"
              class="btn btn-outline-dark"
              style="min-width: 160px"
            >
              T::OPEN CROP
            </button> -->
            <!-- <button
              type="button"
              (click)="openModal(confrimationContent, 'md')"
              class="btn btn-outline-dark"
              style="min-width: 160px"
            >
              T::OPEN CONFRIMATION
            </button> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<app-main-footer></app-main-footer>

<!-- BEGINS:: Subscription Modal -->
<ng-template #subscriptionContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Please select your preferred plan
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="subscriptionForm">
      <div class="plan">
        <input formControlName="plan" type="radio" id="PLAN1" value="1" />
        <label for="PLAN1">
          <div class="plan__info">
            <h5>For Teachers / Institutes</h5>
            <ul>
              <li>Website Listing and Lead Generation</li>
            </ul>
          </div>
          <div class="plan__price">₹ 0 per year</div>
        </label>
      </div>
      <div class="plan">
        <input formControlName="plan" type="radio" id="PLAN2" value="2" />
        <label for="PLAN2">
          <div class="plan__info">
            <h5>For Teachers / Small Institute</h5>
            <ul>
              <li>Website Listing and Lead Generation</li>
              <li>Education Management Platform Basic Version</li>
            </ul>
          </div>
          <div class="plan__price">
            ₹ 99 per month / Teacher (Billed Annually)
          </div>
        </label>
      </div>
      <div class="plan">
        <input formControlName="plan" type="radio" id="PLAN3" value="3" />
        <label for="PLAN3">
          <div class="plan__info">
            <h5>For Schools/College/Institute(Upto 10 Teachers)</h5>
            <ul>
              <li>Website Listing and Lead Generation</li>
              <li>Education Management Platform Basic Version</li>
            </ul>
          </div>

          <div class="plan__price">
            <span
              style="text-decoration: line-through; text-decoration-color: red"
              >₹ 499</span
            >
            <span style="color: red"> ₹ 249</span> per month (Billed Annually)
          </div>
        </label>
      </div>
      <div class="plan">
        <input formControlName="plan" type="radio" id="PLAN4" value="4" />
        <label for="PLAN3">
          <div class="plan__info">
            <h5>For Schools/College/Large Institute</h5>
            <ul>
              <li>Website Listing and Lead Generation</li>
              <li>Education Management Platform Basic Version</li>
            </ul>
          </div>
          <div class="plan__price">
            <span
              style="text-decoration: line-through; text-decoration-color: red"
              >₹ 999</span
            >
            <span style="color: red"> ₹ 249</span> per month (Billed Annually)
          </div>
        </label>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      id="closeCheckoutModal"
      (click)="modal.close()"
    >
      Close
    </button>
    <button
      id="registerSubmitBtn"
      (click)="onSubmit()"
      type="button"
      class="btn btn-primary"
    >
      Register
    </button>
  </div>
</ng-template>
<!-- ENDS:: Subscription Modal -->

<!-- BEGINS:: Confirmation Modal -->
<ng-template #confrimationContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Success!</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body text-center">
    <i class="fa fa-check-circle fa-5x text-success"></i>
    <!-- <i class="fas fa-check-circle fa-5x text-success"></i> -->
    <p class="mt-3">
      Thank you for submitting your details. Our team will review and get in
      touch with you shortly.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      id="closeCheckoutModal"
      (click)="modal.close()"
    >
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="onNavigateToHome()">
      OK
    </button>
  </div>
</ng-template>
<!-- ENDS:: Confirmation Modal -->

<!-- BEGINS:: Crop Modal -->
<ng-template #cropContent let-modal>
  <div class="modal-body">
    <div class="cropper-container">
      <image-cropper
        [imageBase64]="logoImagePreview"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [resizeToWidth]="640"
        format="png"
        (imageCropped)="onCropImage($event)"
      ></image-cropper>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark btn-sm"
      id="closeCheckoutModal"
      (click)="modal.dismiss('Cross click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary btn-sm"
      (click)="saveCroppedImage()"
      id="cropImageBtn"
    >
      OK
    </button>
  </div>
</ng-template>
<!-- for multiple index file crops  -->
<ng-template #cropContentForMultiple let-modal>
  <div class="modal-body">
    <div class="cropper-container">
      <image-cropper
        [imageBase64]="croppedImagesWithIndex[selectedCropIndex]"
        [maintainAspectRatio]="true"
        [aspectRatio]="16 / 9"
        [resizeToWidth]="640"
        format="png"
        (imageCropped)="onCropImagewithIndex($event, selectedCropIndex)"
      ></image-cropper>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark btn-sm"
      id="closeCheckoutModal"
      (click)="modal.dismiss('Cross click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary btn-sm"
      (click)="saveCroppedImagewithIndex(selectedCropIndex)"
      id="cropImageBtn"
    >
      OK
    </button>
  </div>
</ng-template>
