import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { ErrorHandler } from './errorhandler.service';

@Injectable({
  providedIn: 'root',
})
export class InstituteService {
  constructor(private http: HttpClient, private _handler: ErrorHandler) {}

  getInstituteTypes(): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/api/v1/all/instituteType`);
  }

  getStates(): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/api/v1/get/state`);
  }

  getCitiesByStateId(stateId: string): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/api/v1/get/city/by/ObjectId?stateId=${stateId}`
    );
  }

  getClasses(): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/api/v1/all/classes`);
  }

  createInstitute(formData: FormData): Observable<any> {
    return this.http
      .post(`${environment.BASE_URL}/api/v1/create/institute/public`, formData)
      .pipe(catchError(this._handler.handleError));
  }

  getLocalities(city: string, searchTerm = ''): Observable<any> {
    let httpParmas = new HttpParams();
    httpParmas = httpParmas.append('city', city);
    if (searchTerm !== '') httpParmas = httpParmas.append('name', searchTerm);
    return this.http.get(`${environment.BASE_URL}/api/v1/all/localities`, {
      params: httpParmas,
    });
  }
}
