import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { aggregateInstitutes } from '../utils/api.constant';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
@Injectable()
export class ListingService {
  _http = inject(HttpClient);
  httpParams = new HttpParams();

  fetchListing(params: {
    cityId?: string;
    typeId?: string;
    lat?: number;
    lng?: number;
    searchMode?: string;
  }): Observable<ListingFetchResponse> {
    this.httpParams = new HttpParams();
    if (params.cityId)
      this.httpParams = this.httpParams.append('city', params.cityId);

    if (params.typeId)
      this.httpParams = this.httpParams.append('type', params.typeId);

    if (params.lat) this.httpParams = this.httpParams.append('lat', params.lat);

    if (params.lng) this.httpParams = this.httpParams.append('lng', params.lng);

    if (params.searchMode)
      this.httpParams = this.httpParams.append('searchMode', params.searchMode);

    return this._http
      .get<ListingFetchResponse>(aggregateInstitutes, {
        params: this.httpParams,
      })
      .pipe(
        map((_) => {
          _.data.forEach((listing) => {
            if (listing.experienceMin && listing.experienceMax) {
              if (listing.experienceMax === listing.experienceMin)
                `${listing.experienceMax} years experience`;
              else
                listing.experienceText = `${
                  listing.experienceMin + '-' + listing.experienceMax
                } years experience`;
            }
            if (
              listing.instituteLogo &&
              !listing.instituteLogo.includes(environment.BUCKET_URL)
            ) {
              listing.instituteLogo =
                environment.BUCKET_URL + listing.instituteLogo;
            }
            if (listing.distance) {
              if (listing.distance === -1) {
                listing.distanceInText = '';
              } else if (+listing.distance.toFixed(2) > 1) {
                listing.distanceInText =
                  'Approx ' + listing.distance.toFixed(2) + ' kms away';
              } else {
                listing.distanceInText = 'Approx 1 km away';
              }
            }
          });
          return _;
        })
      );
  }
}

export interface ListingFetchResponse {
  success: boolean;
  message: string;
  data: InstituteListings[];
}

export interface InstituteListings {
  instituteName: string;
  instituteLogo: string;
  teachersCount: number;
  experienceMin?: number;
  experienceMax?: number;
  instituteAddress: string;
  experienceText?: string; // for ui purpose only
  _id: string;
  urlCode?: string;
  currentStage?: string;
  distance?: number;
  distanceInText?: string;
  coordinates: string[];
}
